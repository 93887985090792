import React from "react";
import { Box, Text, Textarea, Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import styles from "../styles.module.css";
import InputText from "../../../../components/input";

const Forms = () => {
  let data = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  return (
    <Box>
      <Formik initialValues={data}>
        {({ values, handleChange }) => (
          <Form>
            <Box className={styles.grid}>
              <Box>
                <Text fontSize="12px" fontWeight={500}>
                  First Name
                </Text>
                <InputText
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  handleChange={handleChange}
                  placeholder="first name"
                />
              </Box>
              <Box>
                <Text fontSize="12px" fontWeight={500}>
                  Last Name
                </Text>
                <InputText
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  handleChange={handleChange}
                  placeholder="last name"
                />
              </Box>
            </Box>

            <Box>
              <Text fontSize="12px" fontWeight={500}>
                Email Address
              </Text>
              <InputText
                type="email"
                name="email"
                value={values.email}
                handleChange={handleChange}
                placeholder="email address"
              />
            </Box>

            <Box>
              <Text fontSize="12px" fontWeight={500}>
                Phone Number
              </Text>
              <InputText
                type="text"
                name="phone"
                value={values.phone}
                handleChange={handleChange}
                placeholder="phone number"
              />
            </Box>

            <Box>
              <Text fontSize="12px" fontWeight={500}>
                Message
              </Text>
              <Textarea
                type="text"
                name="message"
                rows="5"
                value={values.message}
                handleChange={handleChange}
                placeholder="Type message"
                border="1px solid #C9C9C9"
                borderRadius={"5px"}
                mt="8px"
                mb="16px"
                _placeholder={{
                  color: "#C9C9C9",
                  textTransform: "capitalize",
                }}
              />
            </Box>

            <Box mt="24px" display="flex" justifyContent="center">
              <Button
                w="288px"
                type="submit"
                bg="#000"
                color="#fff"
                fontSize="14px"
                fontWeight="400"
                borderRadius={"2px"}
                _hover={{
                  background: "#000",
                }}
              >
                Submit{" "}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Forms;
