import React from "react";
import { Formik, Form } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Container,
  Text,
  Input,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HiOutlineFingerPrint } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { getCreating } from "../../redux/selectors";
import { forgot_password } from "../../redux/reducer";

const Forgot = () => {
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();
  let data = {
    email: "",
  };

  const handleSubmit = (doc) => {
    dispatch(forgot_password(doc));
  };
  return (
    <Box>
      <Container
        maxW="container.xl"
        h="100vh"
        display={"grid"}
        placeItems="center"
      >
        <Box>
          <Box
            w="50px"
            h="50px"
            display="grid"
            placeItems="center"
            mb="20px"
            border="1px solid #E0E0E0"
            borderRadius="5px"
            p="10px"
          >
            <HiOutlineFingerPrint size={30} />
          </Box>
          <Text color="#333333" fontSize="25px" fontWeight="700">
            Forgot Password?
          </Text>
          <Text mb="32px" color="#4F4F4F" fontSize="14px" fontWeight="400">
            No worries, we'll send you reset instructions.
          </Text>
          <Formik initialValues={data} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    border="1px solid #E0E0E0"
                    borderRadius={"5px"}
                    h="43px"
                    placeholder="Enter your email"
                    _placeholder={{
                      color: "#E0E0E0",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  />
                </FormControl>

                <Button
                  color="#fff"
                  bg="#000000"
                  type="submit"
                  isLoading={loading}
                  disabled={loading}
                  w="100%"
                  borderRadius={"5px"}
                  h="43px"
                  mt="24px"
                  _hover={{
                    background: "#000",
                  }}
                >
                  Reset Password
                </Button>

                <Link to="/login">
                  <Button
                    color="#000000"
                    border="1px solid #000000"
                    bg="#fff"
                    w="100%"
                    borderRadius={"5px"}
                    h="43px"
                    mt="24px"
                    _hover={{
                      background: "#fff",
                    }}
                  >
                    Back
                  </Button>
                </Link>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default Forgot;
