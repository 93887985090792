// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_box__pOZO0 {\n  width: 360px;\n}\n\n/* mobile */\n@media (max-width: 640px) {\n  .styles_box__pOZO0 {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/template1/resetPassword/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA,WAAW;AACX;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".box {\n  width: 360px;\n}\n\n/* mobile */\n@media (max-width: 640px) {\n  .box {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "styles_box__pOZO0"
};
export default ___CSS_LOADER_EXPORT___;
