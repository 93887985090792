import React, { useState, useEffect } from "react";
import { Box, Container, Text, Icon, Collapse } from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { HiOutlineShoppingBag, HiOutlineUser } from "react-icons/hi";
import styles from "./styles.module.css";
import {
  getCart,
  getStore,
  getUser,
  getCustomer,
} from "../../pages/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { get_cart, get_store, get_customer } from "../../pages/redux/reducer";
import SearchBar from "./searchBar";
import useDebounce from "../../hooks/useDebounce";

const Navbar = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const cart = useSelector(getCart);
  const products = useSelector(getStore);
  const [product, setProduct] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce(searchTerm, 300);
  const customer = useSelector(getCustomer);
  const token = localStorage.getItem("x-store-token");
  const [show, setShow] = useState(false);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_cart());
    dispatch(get_customer());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearch !== "") {
      const handleSearch = () => {
        const search = debouncedSearch.toLowerCase();
        const result = products.filter((data) => {
          return data?.name?.toLowerCase().includes(search);
        });
        setProduct(result);
      };

      handleSearch();
    } else {
      setProduct([]);
    }
  }, [debouncedSearch, products]);

  const logOut = () => {
    localStorage.removeItem("x-store-token");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const routes = [
    {
      name: "shop",
      route: "/shop",
    },
  ];
  return (
    <Box className={styles.nav}>
      <Container maxW="container.xl">
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box
            display="flex"
            gap="96px"
            alignItems="center"
            alignContent="center"
          >
            <Link to="/">
              <Text
                fontSize="24px"
                fontWeight={"700"}
                textTransform="capitalize"
              >
                {user.business_name}
              </Text>
            </Link>

            <Box className={styles.link_container}>
              {routes.map((data, i) => (
                <NavLink
                  to={data.route}
                  key={i}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  {data.name}
                </NavLink>
              ))}
            </Box>
          </Box>

          <Box className={styles.container}>
            <SearchBar product={product} handleChange={handleSearch} />
            {token && Object.keys(customer)?.length >= 1 ? (
              <div className={styles.user}>
                <Box position="relative" display="inline-block">
                  <Box
                    display="flex"
                    gap="10px"
                    onClick={() => setShow(!show)}
                    cursor="pointer"
                  >
                    <Icon as={HiOutlineUser} fontSize="20px" />
                    <Text textTransform="capitalize">{`${customer?.first_name} ${customer?.last_name}`}</Text>
                  </Box>
                  <Collapse in={show} animateOpacity>
                    <Box
                      position="absolute"
                      top="100%"
                      left="0"
                      zIndex="5"
                      bg="#fff"
                      w="100%"
                      boxShadow="0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
                      mt="10px"
                    >
                      <Box p="10px" display="flex" gap="10px" flexDir="column">
                        <Link to="/profile">
                          <Text>Profile</Text>
                        </Link>
                        <Text color="#f64b3c" cursor="pointer" onClick={logOut}>
                          Log out
                        </Text>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </div>
            ) : (
              <Link to="/login" className={styles.icons}>
                <Box display="flex" gap="10px">
                  <Icon as={HiOutlineUser} fontSize="20px" />
                  <Text>Login</Text>
                </Box>
              </Link>
            )}

            <Link to="/cart" className={styles.icons}>
              <Box position="relative">
                <Icon as={HiOutlineShoppingBag} fontSize="20px" />
                {cart?.length >= 1 && (
                  <Box
                    position="absolute"
                    bg="#000"
                    top="-2"
                    left="-3"
                    zIndex="2"
                    w="20px"
                    h="20px"
                    display="grid"
                    placeItems="center"
                    borderRadius="full"
                    color="#fff"
                    fontSize="12px"
                  >
                    {cart?.length}
                  </Box>
                )}
              </Box>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;
