// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_pagination__KR\\+I1 {\n  display: flex;\n  width: 100%;\n  list-style: none;\n  gap: 32px;\n  justify-content: center;\n  padding-top: 10px;\n}\n\n.styles_pagination__KR\\+I1 a {\n  color: #a5a7a8;\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.styles_active__7zz44 a {\n  color: #fff;\n  background-color: #1f1f1f;\n  border-radius: 5px;\n  padding: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 16px;\n  font-weight: 600;\n  width: 20px;\n}\n\n.styles_pagination_link__Tciaa a {\n  /* border: 3px solid #c6cccf; */\n  border-radius: 5px;\n  color: #a5a7a8;\n  font-size: 16px;\n  font-weight: 600;\n  border: 1px solid red;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pagination/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".pagination {\n  display: flex;\n  width: 100%;\n  list-style: none;\n  gap: 32px;\n  justify-content: center;\n  padding-top: 10px;\n}\n\n.pagination a {\n  color: #a5a7a8;\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.active a {\n  color: #fff;\n  background-color: #1f1f1f;\n  border-radius: 5px;\n  padding: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 16px;\n  font-weight: 600;\n  width: 20px;\n}\n\n.pagination_link a {\n  /* border: 3px solid #c6cccf; */\n  border-radius: 5px;\n  color: #a5a7a8;\n  font-size: 16px;\n  font-weight: 600;\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": "styles_pagination__KR+I1",
	"active": "styles_active__7zz44",
	"pagination_link": "styles_pagination_link__Tciaa"
};
export default ___CSS_LOADER_EXPORT___;
