// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_personal__YJ856 {\n  display: flex;\n  gap: 20px;\n  flex-direction: row;\n  align-items: center;\n}\n\n.styles_name__POBGW {\n  text-align: left;\n  color: #000;\n  font-weight: 500;\n  font-size: 20px;\n  text-transform: capitalize;\n}\n\n.styles_sub__JJF9b {\n  font-size: 12px;\n  color: #98a2b3;\n  text-align: left;\n}\n\n@media (max-width: 640px) {\n  .styles_personal__YJ856 {\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .styles_name__POBGW {\n    text-align: center;\n    color: #000;\n    font-weight: 500;\n    font-size: 20px;\n    text-transform: capitalize;\n  }\n\n  .styles_sub__JJF9b {\n    font-size: 12px;\n    color: #98a2b3;\n    text-align: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/template1/profile/components/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;AACF","sourcesContent":[".personal {\n  display: flex;\n  gap: 20px;\n  flex-direction: row;\n  align-items: center;\n}\n\n.name {\n  text-align: left;\n  color: #000;\n  font-weight: 500;\n  font-size: 20px;\n  text-transform: capitalize;\n}\n\n.sub {\n  font-size: 12px;\n  color: #98a2b3;\n  text-align: left;\n}\n\n@media (max-width: 640px) {\n  .personal {\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .name {\n    text-align: center;\n    color: #000;\n    font-weight: 500;\n    font-size: 20px;\n    text-transform: capitalize;\n  }\n\n  .sub {\n    font-size: 12px;\n    color: #98a2b3;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personal": "styles_personal__YJ856",
	"name": "styles_name__POBGW",
	"sub": "styles_sub__JJF9b"
};
export default ___CSS_LOADER_EXPORT___;
