// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_grid__MHt4B {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 30px;\n  margin-top: 30px;\n}\n\n.styles_text__TJT\\+f {\n  font-size: 70px;\n  font-weight: 700;\n  text-align: center;\n  color: #fff;\n}\n\n@media (max-width: 640px) {\n  .styles_grid__MHt4B {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 24px;\n    margin-top: 24px;\n  }\n\n  .styles_text__TJT\\+f {\n    font-size: 32px;\n    font-weight: 700;\n    text-align: center;\n    color: #fff;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/template1/home/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;EACb;AACF","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 30px;\n  margin-top: 30px;\n}\n\n.text {\n  font-size: 70px;\n  font-weight: 700;\n  text-align: center;\n  color: #fff;\n}\n\n@media (max-width: 640px) {\n  .grid {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 24px;\n    margin-top: 24px;\n  }\n\n  .text {\n    font-size: 32px;\n    font-weight: 700;\n    text-align: center;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "styles_grid__MHt4B",
	"text": "styles_text__TJT+f"
};
export default ___CSS_LOADER_EXPORT___;
