import React from "react";
import { Box, Container, Text, useMediaQuery } from "@chakra-ui/react";

const Footer = () => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  return (
    <Box
      bg="#171717"
      color="#fff"
      position="relative"
      left="0"
      h={"auto"}
      bottom="0"
      w="100%"
      boxSizing="border-box"
      mt="100px"
    >
      <Container
        maxW="container.xl"
        pt="50px"
        pb="50px"
        w="100%"
        boxSizing="border-box"
      >
        <Box
          display="flex"
          flexDir={isLargerThan800 ? "row" : "column"}
          alignItems="center"
          justifyContent={isLargerThan800 ? "space-between" : null}
          gap={isLargerThan800 ? "24px" : "24px"}
        >
          <Box>
            <Text fontSize="12px">Powered by</Text>
            <Text fontSize="20px" fontWeight="700">
              Retailoop Technologies
            </Text>
          </Box>

          <Text>© 2023. All rights reserved.</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
