import React from "react";
import { Box, Icon } from "@chakra-ui/react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCart } from "../../pages/redux/selectors";

const CartIcon = () => {
  const cart = useSelector(getCart);

  return (
    <>
      {cart?.length >= 1 && (
        <Link to="/cart">
          <Box className={styles.base}>
            <Box
              color="#000"
              bg="#fff"
              borderRadius={"full"}
              position="absolute"
              top="0"
              right="0"
              left="60%"
              fontSize="16px"
              fontWeight="600"
              display="flex"
              justifyContent="center"
              placeItems="center"
            >
              {cart?.length}
            </Box>
            <Icon as={HiOutlineShoppingBag} fontSize="20px" color="#fff" />
          </Box>
        </Link>
      )}
    </>
  );
};

export default CartIcon;
