import React from "react";
import { Box, Input } from "@chakra-ui/react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";

const SearchBar = ({ product, handleChange }) => {
  return (
    <div className={styles.dropdown_menu}>
      <Box className={styles.dropdown_toggle}>
        <Box
          display="flex"
          borderRadius="10px"
          boxShadow="0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
          py="8px"
          px="8px"
          w="100%"
          alignItems="stretch"
        >
          <Input
            type="text"
            px="10px"
            onChange={handleChange}
            border="none"
            outline="none"
            focusBorderColor="transparent"
            placeholder="What are you looking for?"
            _placeholder={{
              color: "#C0C0C0",
              fontSize: "12px",
              fontWeight: "400",
            }}
          />
          <Box
            w="48px"
            borderRadius="12px"
            display="grid"
            placeItems="center"
            bg="#171717"
          >
            <BsSearch color="#fff" />
          </Box>
        </Box>
      </Box>
      {product?.length >= 1 && (
        <Box className={styles.dropdown_content}>
          <Box
            className={styles.content}
            display="flex"
            flexDirection={"column"}
            gap="5px"
          >
            {product.map((data, idx) => (
              <Link key={data.id + idx} to={`/product/${data.id}`}>
                <Box
                  p="5px"
                  textTransform={"capitalize"}
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="500"
                  _hover={{ background: "#C9C9C9" }}
                >
                  {data.name}
                </Box>
              </Link>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SearchBar;
