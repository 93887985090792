import React from "react";
import { useEffect, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  getLoadingOrders,
  getOrders,
  getOrdersPagination,
  getSingleOrder,
  getUser,
} from "../../../../redux/selectors";
import {
  cancel_single_order,
  get_customer,
  get_orders,
  get_single_order,
  get_store,
} from "../../../../redux/reducer";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import commaNumber from "comma-number";
import moment from "moment";
import Badge from "../../../../../components/badge";
import styles from "./styles.module.css";
import View from "./view";
import Pagination from "../../../../../components/pagination";

const OrdersTab = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);
  const orders = useSelector(getOrders);
  const [show, setShow] = useState(false);
  const order = useSelector(getSingleOrder);
  const [viewOrder, setViewOrder] = useState({});
  const [order_id, setOrderId] = useState("");
  const loading = useSelector(getLoadingOrders);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];
  const pagination = useSelector(getOrdersPagination);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_customer());
  }, [dispatch]);

  useEffect(() => {
    if (customer?.id && user?.id) {
      dispatch(
        get_orders({ user_id: user.id, customer_id: customer.id, currentPage }),
      );
    }
  }, [dispatch, user, customer, currentPage]);

  useEffect(() => {
    if (order_id !== "") {
      dispatch(
        get_single_order({
          id: order_id,
          user_id: user.id,
          customer_id: customer.id,
        }),
      );
    }
  }, [dispatch, user, customer, order_id]);

  useEffect(() => {
    if (Object?.keys(order)?.length >= 1) {
      setViewOrder(order);
    }
  }, [order]);

  useEffect(() => {
    if (Object.keys(viewOrder).length >= 1 && show === false) {
      setShow(true);
    }
  }, [viewOrder, show]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  // const onOpen = (doc) => {
  //   setOrderId(doc);
  // };

  const onClose = () => {
    dispatch(cancel_single_order());
    setViewOrder({});
    setOrderId("");
    setShow(!show);
  };

  const changePage = (event) => {
    const { selected } = event;
    setCurrentPage(selected + 1);
    setSelected(selected);
  };
  return (
    <Box pb="100px">
      {loading ? (
        <Box w="100%" h="100vh" display="grid" placeItems="center">
          <Spinner size="xl" color="#000" />
        </Box>
      ) : (
        <>
          <View show={show} close={onClose} order={viewOrder} />
          <TableContainer maxW="100%" overflowX="auto">
            <Table>
              <Thead className={styles.table_head}>
                <Tr>
                  <Th>Order ID</Th>
                  <Th>Items</Th>
                  <Th>Date Paid</Th>
                  <Th>Status</Th>
                  <Th>Total</Th>
                  {/* <Th></Th> */}
                </Tr>
              </Thead>
              <Tbody className={styles.table_body}>
                {orders.map((data, idx) => (
                  <Tr key={data.id + idx}>
                    <Td>{data.unique_id}</Td>
                    <Td>{data.products.length}</Td>
                    <Td>{moment(data.date_created).format("LL")}</Td>
                    <Td>
                      <Badge status={data.status} />{" "}
                    </Td>
                    <Td>N{commaNumber(data.total_amount)}</Td>
                    {/* <Td>
                      <Button
                        variant="outline"
                        border="1px solid #D0D5DD"
                        borderRadius={"20px"}
                        color="#98A2B3"
                        onClick={() => onOpen(data.id)}
                        size="sm"
                      >
                        View
                      </Button>
                    </Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {orders?.length >= 1 && (
            <Pagination
              pageCount={pagination.totalPages}
              changePage={changePage}
              selected={selected}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default OrdersTab;
