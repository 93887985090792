import React from "react";
import { Skeleton, Box } from "@chakra-ui/react";

const CardLoader = () => {
  return (
    <Box cursor="pointer" h="auto">
      <Skeleton h="150px" w="100%" maxW="100%" bg='red' />

      <Skeleton mt="16px" h="15px" w="100px" />
      <Skeleton mt="16px" h="15px" w="100px" />

      {/* <Skeleton mt="10px" h="40px" w="176px" borderRadius={"2px"} /> */}
    </Box>
  );
};

export default CardLoader;
