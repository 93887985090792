import { createSelector } from "@reduxjs/toolkit";

const storeData = (state = {}) => state.details;

export const getStore = createSelector(
  storeData,
  (storeData) => storeData.store,
);

export const getLoading = createSelector(
  storeData,
  (storeData) => storeData.ui.loading,
);

export const getUser = createSelector(storeData, (storeData) => storeData.user);

export const getUserShipping = createSelector(
  storeData,
  (storeData) => storeData.user_shipping,
);

export const getMerchant = createSelector(
  storeData,
  (storeData) => storeData.merchant,
);

export const getShipping = createSelector(
  storeData,
  (storeData) => storeData.shipping,
);

export const getProduct = createSelector(
  storeData,
  (storeData) => storeData.product,
);

export const getCart = createSelector(storeData, (storeData) => storeData.cart);

export const getLoadingProduct = createSelector(
  storeData,
  (storeData) => storeData.ui.loadingProduct,
);

export const getCreating = createSelector(
  storeData,
  (storeData) => storeData.ui.creating,
);

export const getCreatingCustomer = createSelector(
  storeData,
  (storeData) => storeData.ui.creating_user,
);

export const getUpdating = createSelector(
  storeData,
  (storeData) => storeData.ui.updating,
);

export const getCustomer = createSelector(
  storeData,
  (storeData) => storeData.customer,
);

export const getOrders = createSelector(
  storeData,
  (storeData) => storeData.orders,
);
export const getSingleOrder = createSelector(
  storeData,
  (storeData) => storeData.single_order,
);

export const getTaxes = createSelector(storeData, (storeData) => storeData.tax);

export const getLoadingOrders = createSelector(
  storeData,
  (storeData) => storeData.ui.loadingOrders,
);

export const getOrdersPagination = createSelector(
  storeData,
  (storeData) => storeData.orders_pagination,
);
