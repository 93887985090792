import React from "react";
import { Box, Text } from "@chakra-ui/react";

const EmptyState = ({ text }) => {
  return (
    <Box h="100vh" w="100%" display="grid" placeItems="center">
      <Box>
        <Text fontWeight="700" fontSize="32px" textAlign="center">
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyState;
