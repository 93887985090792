import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
} from "@chakra-ui/react";
import AuthLogin from "./login";
import AuthSignUp from "./signup";

const Auth = ({ open, close }) => {
  const [active, setActive] = useState("login");

  const auth = {
    login: <AuthLogin />,
    signup: <AuthSignUp />,
  };

  const tabs = [
    {
      name: "log in",
      value: "login",
    },
    {
      name: "sign up",
      value: "signup",
    },
  ];
  return (
    <Modal isOpen={open} onClose={close} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ModalHeader
            fontSize="16px"
            fontWeight="600"
            whiteSpace="nowrap"
          ></ModalHeader>
          <ModalCloseButton />
        </Box>

        <ModalBody pb="32px">
          <Box display="grid" justifyContent="center">
            <Box display={"flex"} gap="48px" alignItems="center">
              {tabs.map((tab, idx) => (
                <Text
                  key={idx + 1}
                  textTransform="capitalize"
                  fontSize="18px"
                  fontWeight="500"
                  color={active === tab.value ? "#171717" : "#1e1e1e80"}
                  onClick={() => setActive(tab.value)}
                  cursor="pointer"
                >
                  {tab.name}
                </Text>
              ))}
            </Box>
          </Box>

          {active === "login" ? auth.login : auth.signup}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Auth;
