import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Container,
  Input,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCreatingCustomer, getUser } from "../../redux/selectors";
import { useEffect } from "react";
import { create_customer, get_store } from "../../redux/reducer";

const SignUp = () => {
  const [show, setShow] = useState(false);
  const [length, setLength] = useState(false);
  const [caseCheck, setCase] = useState(false);
  const [character, setCharacter] = useState(false);
  const [isEmpty, setIsempty] = useState(true);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const loading = useSelector(getCreatingCustomer);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  const handlePassword = (text) => {
    const specialCharReg = new RegExp('[!@#$%^&*(),.?": {} |<>]');
    const casingReg = new RegExp("(?=.*[a-z])(?=(.*[A-Z]))");
    setIsempty(!text.length > 0);
    setLength(text.length >= 8);
    setCase(casingReg.test(text));
    setCharacter(specialCharReg.test(text));
  };

  let data = {
    first_name: "",
    last_name: "",
    email_address: "",
    password: "",
    phone_number: "",
  };

  const handleSubmit = (doc) => {
    const formatted_number = doc.phone_number.replace(/\+/g, "");
    let data = {
      ...doc,
      user_id: user?.id,
      phone_number: formatted_number,
    };
    dispatch(create_customer(data));
  };

  const Schema = Yup.object().shape({
    email_address: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be at least  8 characters")
      .matches('[!@#$%^&*(),.?": {} |<>]', "No special character"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .min(13)
      .max(14),
  });

  return (
    <Box bg="#fff" pb="50px">
      <Box p="10px">
        <Link to="/" style={{ textDecoration: "none" }}>
          Home
        </Link>
      </Box>

      <Container
        maxW="container.xl"
        h="100vh"
        display="grid"
        placeItems="center"
      >
        <Box className={styles.box}>
          <Text color="#333333" fontSize="36px" fontWeight="700">
            Sign Up
          </Text>
          <Text mb="32px" color="#4F4F4F" fontSize="14px" fontWeight="400">
            Create an account.
          </Text>
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setFieldTouched,
            }) => (
              <Form>
                <Box display="flex" flexDir="column" gap="20px">
                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      First Name
                    </FormLabel>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values.first_name}
                      name="first_name"
                      border="1px solid #E0E0E0"
                      borderRadius={"5px"}
                      textTransform="capitalize"
                      h="43px"
                      placeholder="Enter your name"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Last Name
                    </FormLabel>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values.last_name}
                      name="last_name"
                      border="1px solid #E0E0E0"
                      textTransform="capitalize"
                      borderRadius={"5px"}
                      h="43px"
                      placeholder="Enter your name"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Email
                    </FormLabel>
                    <Input
                      type="email"
                      onChange={handleChange}
                      value={values.email_address}
                      name="email_address"
                      border="1px solid #E0E0E0"
                      borderRadius={"5px"}
                      h="43px"
                      placeholder="Enter your email"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Phone Number
                    </FormLabel>
                    <PhoneInput
                      className={styles.container}
                      defaultCountry="NG"
                      placeholder="0000000"
                      name="phone"
                      onChange={(phone) => setFieldValue("phone_number", phone)}
                      onBlur={(event) => {
                        setFieldTouched("phone_number", true, false);
                        handleBlur("phone_number")(event);
                      }}
                      required
                    />

                    {errors.phone_number && touched.phone_number && (
                      <Text color="red" mb="5px" fontSize="12px" mt="3px">
                        {errors.phone_number}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type={show ? "text" : "password"}
                        // maxLength={8}
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                          handlePassword(e.target.value);
                        }}
                        value={values.password}
                        onBlur={handleBlur}
                        name="password"
                        border="1px solid #E0E0E0"
                        borderRadius={"5px"}
                        h="43px"
                        placeholder="********"
                        _placeholder={{
                          color: "#E0E0E0",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      />
                      <InputRightElement>
                        {show ? (
                          <AiOutlineEyeInvisible
                            size={20}
                            cursor="pointer"
                            onClick={() => setShow(!show)}
                          />
                        ) : (
                          <AiOutlineEye
                            size={20}
                            cursor="pointer"
                            onClick={() => setShow(!show)}
                          />
                        )}
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && touched.password && (
                      <Text color="red" mb="5px" fontSize="12px" mt="3px">
                        {errors.password}
                      </Text>
                    )}

                    {!isEmpty && (
                      <Box display="flex" flexDir="column" gap="8px" mt="20px">
                        <Box display="flex" gap="8px" alignItems="center">
                          {length ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            color={length ? "#10AA69" : "red"}
                            fontSize="12px"
                          >
                            Must be at least 8 characters.
                          </Text>
                        </Box>

                        <Box display="flex" gap="8px" alignItems="center">
                          {caseCheck ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            whiteSpace="nowrap"
                            fontSize="12px"
                            color={caseCheck ? "#10AA69" : "red"}
                          >
                            {" "}
                            Must have at least one uppercase and one lowercase
                            letter.
                          </Text>
                        </Box>

                        <Box display="flex" gap="8px" alignItems="center">
                          {character ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            color={character ? "#10AA69" : "red"}
                            fontSize="12px"
                          >
                            {" "}
                            Must include special characters !@£$%&.
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </FormControl>
                </Box>

                <Button
                  color="#fff"
                  bg="#000000"
                  type="submit"
                  isLoading={loading}
                  disabled={loading}
                  w="100%"
                  borderRadius={"5px"}
                  h="43px"
                  mt="24px"
                  mb="30px"
                  _hover={{
                    background: "#000",
                  }}
                >
                  Sign up
                </Button>

                <Text
                  textAlign="center"
                  color="#4F4F4F"
                  fontSize="14px"
                  fontWeight="400"
                >
                  Already have an account?
                  <Link to="/login" className={styles.link}>
                    Log In
                  </Link>
                </Text>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUp;
