import React, { useEffect } from "react";
import { Template1 } from "./pages/template1";
import { useDispatch, useSelector } from "react-redux";
import { get_store } from "./pages/redux/reducer";
import { getMerchant, getUser } from "./pages/redux/selectors";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import logo from "../src/assets/favicon.png";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];
  const merchant = useSelector(getMerchant);

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    const { business_name } = user;
    const storename =
      business_name?.charAt(0).toUpperCase() + business_name?.slice(1);
    document.title = storename || "Retailloop Store";
  }, [user]);

  return (
    <div>
      <Toaster />
      <Helmet>
        <link rel="icon" type="image/png" href={user.brandPhoto || logo} />
        <link rel="apple-touch-icon" href={user.brandPhoto || logo} />
        <meta name="title" content={merchant?.homePageTitle} />
        <meta name="og:title" content={merchant?.homePageTitle} />
        <meta name="description" content={merchant?.homePageDescription} />
        <title>{merchant.business_name}</title>
      </Helmet>
      <Template1 />
    </div>
  );
};

export default App;
