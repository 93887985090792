import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";
import commaNumber from "comma-number";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const Info = ({
  product,
  handleQuantity,
  addToCart,
  handleSize,
  handleColor,
  size,
  color,
  quantity,
  shipping,
  user_shipping,
  handleShipping,
}) => {
  return (
    <Box>
      <Text
        fontSize="32px"
        fontWeight={"600"}
        mb="8px"
        textTransform="capitalize"
      >
        {product?.name}
      </Text>
      {product?.sku_code && (
        <Text fontSize="14px" fontWeight={"400"} mb="24px" color="#707070">
          SKU: {product?.sku_code}
        </Text>
      )}

      <Text fontSize="14px" fontWeight={"400"} mb="24px" color="#707070">
        In Stock: {product?.quantity}
      </Text>

      <Text fontSize="24px" fontWeight={"700"} mb="24px" color="#000">
        {product?.currency}
        {commaNumber(product?.price)}
      </Text>

      {product?.variant_options?.length >= 1 && (
        <>
          {product?.variant_options?.map((data, idx) => (
            <Box key={idx}>
              {data.name.toLowerCase() === "size" && (
                <>
                  <Text fontSize="12px" fontWeight={500} mb="8px">
                    Available Size
                  </Text>
                  <Box display="flex" flexWrap="wrap" gap="10px" mb="20px">
                    {data.option.map((data, id) => (
                      <Box
                        key={id + idx + 1}
                        border="1px solid #171717"
                        bg={size === data.value ? "#171717" : "#fff"}
                        fontWeight="600"
                        color={size === data.value ? "#fff" : "#171717"}
                        p="10px"
                        h="50px"
                        w="50px"
                        borderRadius={"5px"}
                        textTransform="uppercase"
                        cursor="pointer"
                        display="grid"
                        placeItems="center"
                        onClick={() => handleSize(data.value)}
                      >
                        {data.value}
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              {data.name.toLowerCase() === "color" && (
                <>
                  <Text fontSize="12px" fontWeight={500} mb="8px">
                    Available Color
                  </Text>
                  <Box display="flex" flexWrap="wrap" gap="10px" mb="20px">
                    {data.option.map((data, id) => (
                      <Box display="inline-block" key={id + 1 + idx}>
                        <Box
                          // display="grid"
                          // placeItems="center"
                          h="auto"
                          borderRadius="full"
                          onClick={() => handleColor(data.value)}
                          border={
                            color === data.value ? "3px solid #171717" : null
                          }
                        >
                          <Box
                            bg={`${data.value}`}
                            h="20px"
                            w="20px"
                            borderRadius="full"
                            cursor={"pointer"}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          ))}
        </>
      )}

      <Text fontSize="12px" fontWeight={500} mb="8px">
        Quantity
      </Text>
      <Box display="inline-block">
        <Box
          p="5px"
          border="1px solid #171717"
          borderRadius="5px"
          display="flex"
          justifyContent="space-between"
          gap="30px"
          alignItems="center"
        >
          <Box
            w="21px"
            h="21px"
            display="grid"
            placeItems="center"
            cursor="pointer"
            onClick={() => handleQuantity("Decrement")}
          >
            <AiOutlineMinus />
          </Box>
          <Box>{quantity}</Box>
          <Box
            w="21px"
            h="21px"
            display="grid"
            placeItems="center"
            cursor="pointer"
            onClick={
              product?.quantity === quantity
                ? null
                : () => handleQuantity("Increment")
            }
          >
            <AiOutlinePlus />
          </Box>
        </Box>
      </Box>

      <Box mt="16px" mb="32px">
        <Text fontSize="12px" fontWeight={500} mb="8px">
          Choose Delivery Method
        </Text>
        <Box>
          {shipping.map((data) => (
            <Box key={data.id} display="flex" gap="16px" flexWrap="wrap">
              {data.rates.map((rate) => (
                <Box
                  key={rate.id}
                  onClick={() => handleShipping(rate)}
                  border="1px solid #171717"
                  borderRadius={"5px"}
                  cursor="pointer"
                  flex="1"
                  px="10px"
                  py="10px"
                  bg={user_shipping?.name === rate.name ? "#171717" : "#fff"}
                  color={user_shipping?.name === rate.name ? "#fff" : "#171717"}
                >
                  <Text
                    textTransform={"capitalize"}
                    fontSize="14px"
                    fontWeight="600"
                  >
                    {rate.name}
                  </Text>
                  <Text
                    textTransform={"capitalize"}
                    fontSize="12px"
                    fontWeight={"500"}
                  >
                    price: N{commaNumber(rate.price)}
                  </Text>
                  <Text fontSize="12px" fontWeight="500" whiteSpace={"nowrap"}>
                    Timeline: {rate.processingTime}
                  </Text>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>

      {product?.quantity <= 0 ? (
        <Box
          bg="#FCD1D1"
          borderRadius="5px"
          p="4px"
          mb="24px"
          fontSize="14px"
          fontWeight="500"
          color="#EE1717"
          textAlign={"center"}
          textTransform="capitalize"
          h="45px"
          display="flex"
          placeItems={"center"}
          justifyContent={"center"}
        >
          Out of Stock{" "}
        </Box>
      ) : (
        <Button
          mb="24px"
          bg="#171717"
          color="#fff"
          borderRadius="5px"
          fontSize="14px"
          w="100%"
          onClick={addToCart}
          fontWeight="400"
          _hover={{
            background: "#171717",
          }}
        >
          Add To Cart
        </Button>
      )}

      {Object.keys(user_shipping).length <= 0 && (
        <Text textAlign={"center"} color="red">
          Please Choose a Delivery Method!!!
        </Text>
      )}
    </Box>
  );
};

export default Info;
