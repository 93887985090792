import React from "react";
import SideModal from "../../../../../components/sideModal";
import { Box, Text, Button } from "@chakra-ui/react";
import commaNumber from "comma-number";
import { Link } from "react-router-dom";
import moment from "moment";
import Badge from "../../../../../components/badge";

const View = ({ show, close, order }) => {
  return (
    <SideModal show={show} close={close} title="Order">
      <Badge status={order?.status} />
      <Box mt="10px">
        <Text fontWeight="600">Order-{order?.unique_id}</Text>
        <Text color="#667085">{order?.products?.length} item</Text>
        <Text color="#667085">
          Placed on {moment(order?.date_created).format("LL")}
        </Text>
      </Box>

      <Box display="flex" gap="10px" flexDir={"column"} mt="24px">
        <Text textTransform={"uppercase"} fontWeight="600">
          Items in your order
        </Text>
        {order?.products?.map((data, idx) => (
          <Box key={data.id + idx} border="1px solid #D0D5DD" p="10px">
            <Text textTransform={"capitalize"}>{data.name}</Text>
            <Text color="#667085">QTY: {data.quantity}</Text>
            <Text color="#667085">{commaNumber(data.price)}</Text>
            <Link to={`/product/${data.id}`}>
              <Button
                size="md"
                bg="#000"
                color="#fff"
                mt="10px"
                w="100%"
                onClick={close}
              >
                BUY AGAIN
              </Button>
            </Link>
          </Box>
        ))}

        <Text textTransform={"uppercase"} fontWeight="600">
          Payment Information
        </Text>
        <Box border="1px solid #D0D5DD" p="10px">
          <Text fontWeight="500">Payment Method</Text>
          <Text color="#667085" textTransform={"capitalize"}>
            {order?.paymentType}
          </Text>

          <Text fontWeight="500" mt="10px">
            Payment Details
          </Text>
          <Text color="#667085">
            Items total: {commaNumber(order?.total_amount)}
          </Text>
        </Box>

        <Text textTransform={"uppercase"} fontWeight="600">
          Delivery Information
        </Text>
        <Box border="1px solid #D0D5DD" p="10px">
          <Text fontWeight="500">Shipping </Text>
          <Text
            textTransform={"capitalize"}
            color="#667085"
          >{`${order?.first_name} ${order?.last_name}`}</Text>
        </Box>
      </Box>
    </SideModal>
  );
};

export default View;
