import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Container,
  Text,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { CgPassword } from "react-icons/cg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "./styles.module.css";

const ResetPassword = () => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  const validatePassword = (values) => {
    const errors = {};

    if (!values.newPassword) {
      errors.newPassword = "No password provided.";
    } else if (values.newPassword.length < 8) {
      errors.newPassword =
        "Password is too short - should be 8 characters minimum.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+,.?":{}|<>]).*/.test(
        values.newPassword,
      )
    ) {
      errors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character.";
    }

    if (values.newPassword && values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = "Both passwords need to be the same";
    }

    return errors;
  };
  const handleSubmit = (doc) => {
    console.log(doc);
  };
  return (
    <Box>
      <Container
        maxW="container.xl"
        h="100vh"
        display={"grid"}
        placeItems="center"
      >
        <Box className={styles.box}>
          <Box
            w="50px"
            h="50px"
            display="grid"
            placeItems="center"
            mb="20px"
            border="1px solid #E0E0E0"
            borderRadius="5px"
            p="10px"
          >
            <CgPassword size={30} />
          </Box>
          <Text color="#333333" fontSize="25px" fontWeight="700">
            Set new password
          </Text>
          <Text mb="32px" color="#4F4F4F" fontSize="14px" fontWeight="400">
            Must be at least 8 characters
          </Text>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            onSubmit={handleSubmit}
            validate={validatePassword}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              errors,
              handleBlur,
              touched,
            }) => (
              <Form>
                <FormControl isRequired>
                  <FormLabel>New Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={show ? "text" : "password"}
                      onChange={(e) => {
                        setFieldValue("newPassword", e.target.value);
                      }}
                      value={values.newPassword}
                      onBlur={handleBlur}
                      name="newPassword"
                      border="1px solid #E0E0E0"
                      borderRadius={"5px"}
                      h="43px"
                      placeholder="*********"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                    <InputRightElement>
                      {show ? (
                        <AiOutlineEyeInvisible
                          size={20}
                          cursor="pointer"
                          onClick={() => setShow(!show)}
                        />
                      ) : (
                        <AiOutlineEye
                          size={20}
                          cursor="pointer"
                          onClick={() => setShow(!show)}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors.newPassword && touched.newPassword && (
                    <Text color="red" mb="5px" fontSize="12px" mt="3px">
                      {errors.newPassword}
                    </Text>
                  )}
                </FormControl>

                <FormControl isRequired>
                  <FormLabel mt="20px">Confirm Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={hide ? "text" : "password"}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      name="confirmPassword"
                      border="1px solid #E0E0E0"
                      borderRadius={"5px"}
                      h="43px"
                      placeholder="*********"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                    <InputRightElement>
                      {hide ? (
                        <AiOutlineEyeInvisible
                          size={20}
                          cursor="pointer"
                          onClick={() => setHide(!hide)}
                        />
                      ) : (
                        <AiOutlineEye
                          size={20}
                          cursor="pointer"
                          onClick={() => setHide(!hide)}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <Text color="red" mt="5px" fontSize="12px">
                      {errors.confirmPassword}
                    </Text>
                  )}
                </FormControl>

                <Button
                  color="#fff"
                  bg="#000000"
                  type="submit"
                  // isLoading={loading}
                  // disabled={loading}
                  w="100%"
                  borderRadius={"5px"}
                  h="43px"
                  mt="24px"
                  _hover={{
                    background: "#000",
                  }}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPassword;
