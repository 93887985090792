import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { Box, Text, Container, Center, Button } from "@chakra-ui/react";
import styles from "./styles.module.css";
import CartCard from "./component/cartCard";
import Summary from "./component/summary";
import AddShippingLocation from "./component/addShippingLocation";
import { useSelector, useDispatch } from "react-redux";
import {
  getCart,
  getCustomer,
  getUser,
  getCreating,
  getUserShipping,
  getShipping,
  getMerchant,
  getTaxes,
} from "../../redux/selectors";
import {
  create_order,
  get_cart,
  get_customer,
  get_shipping,
  get_store,
  get_taxes,
  remove_item_from_cart,
} from "../../redux/reducer";
import { Link } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import Loader from "../../../components/loader";
import Auth from "./component/auth";
import ToastMessage from "../../../components/toast";

const Cart = () => {
  const [show, setShow] = useState(false);
  const cart = useSelector(getCart);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const customer = useSelector(getCustomer);
  const user = useSelector(getUser);
  const loading = useSelector(getCreating);
  const [isAddressFilled, setIsAddressFilled] = useState({});
  const shipping = useSelector(getUserShipping);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const token = localStorage.getItem("x-store-token");
  const [open, setOpen] = useState(false);
  const [hasMatch, setHasMatch] = useState(false);
  const merchant_shipping = useSelector(getShipping);
  const merchant = useSelector(getMerchant);
  const tax = useSelector(getTaxes);
  const [taxValue, setTaxValue] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  useEffect(() => {
    const total = cart.reduce((prev, curr) => {
      return prev + curr?.price * curr?.quantity;
    }, 0);
    setSubTotal(total);
  }, [cart]);

  useEffect(() => {
    if (merchant?.id) {
      dispatch(get_taxes(merchant.id));
      dispatch(get_shipping(merchant.id));
    }
  }, [dispatch, merchant]);

  useEffect(() => {
    const result = taxValue?.map((tax) => {
      const taxAmount = (parseInt(tax.taxPercentage) / 100) * subTotal;
      const amountFormatted = parseFloat(taxAmount.toFixed(2));
      return {
        ...tax,
        taxAmount: amountFormatted,
      };
    });
    setTaxes(result);
  }, [subTotal, taxValue]);

  useEffect(() => {
    const result = tax.filter((tax) => tax.status);
    setTaxValue(result);
  }, [tax]);

  useEffect(() => {
    if (shipping?.price) {
      setDeliveryFee(shipping?.price);
    }
  }, [shipping]);

  useEffect(() => {
    const subtotal = subTotal + deliveryFee;
    const totalAmount =
      subtotal + taxes.reduce((total, tax) => total + tax.taxAmount, 0);
    setTotal(totalAmount);
  }, [deliveryFee, subTotal, taxes]);

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_cart());
    dispatch(get_customer());
  }, [dispatch]);

  useEffect(() => {
    const result = {
      address: customer.address,
      city: customer.city,
      country: customer.country,
      state: customer.state,
    };
    const allValuesEmpty = Object.values(result).every((value) => !value);
    setIsAddressFilled(allValuesEmpty);
  }, [customer]);

  useEffect(() => {
    if (customer.state) {
      const filteredShipping = merchant_shipping.some((data) => {
        const stateData = data.states.some(
          (state) => state === `${customer.state} State`,
        );
        return stateData;
      });
      setHasMatch(!filteredShipping);
    }
  }, [customer, merchant_shipping]);

  const removeItemFromCart = (doc) => {
    dispatch(remove_item_from_cart(doc));
  };

  const config = {
    email: customer?.email_address,
    amount: total * 100,
    subaccount: user?.subAccountCode,
    bearer: "subaccount",
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const onSuccess = (reference) => {
    const products = cart.map((data) => {
      return {
        product_id: data.id,
        quantity: data.quantity,
        size: data?.size,
        color: data?.color,
      };
    });

    let data = {
      customer_id: customer.id,
      total_amount: total,
      items: products,
      shipping: shipping,
      reference: reference.reference,
    };
    dispatch(create_order({ id: user?.id, data }));
  };

  const initializePayment = usePaystackPayment(config);

  const canCheckout = () => {
    if (deliveryFee === 0) {
      return false;
    }
    if (hasMatch === true) {
      return false;
    }

    return true;
  };

  const handleUser = () => {
    setOpen(true);
  };

  return (
    <Layout>
      <Loader loading={loading} />
      <Container maxW="container.xl" pb="100px" pt="64px">
        <AddShippingLocation open={show} close={() => setShow(!show)} />
        <Auth open={open} close={() => setOpen(false)} />
        {isAddressFilled ? (
          <ToastMessage
            show={isAddressFilled && Object.keys(customer).length >= 1}
            onClose={() => isAddressFilled(false)}
          >
            <Text>
              Please add your address{" "}
              <Link to="/profile" style={{ textDecoration: "underline" }}>
                Go to Profile
              </Link>
            </Text>
          </ToastMessage>
        ) : (
          <ToastMessage
            show={
              hasMatch &&
              Object.keys(customer)?.length >= 1 &&
              cart?.length >= 1
            }
            onClose={() => setHasMatch(false)}
            type="error"
          >
            Seems we do not deliver to your state
          </ToastMessage>
        )}

        <Text mb="40px" fontSize="16px" fontWeight="600">
          My Cart({cart?.length})
        </Text>
        {cart?.length >= 1 ? (
          <Box className={styles.box}>
            <Box className={styles.content}>
              {cart.map((cart) => (
                <CartCard
                  key={cart.product_id}
                  cart={cart}
                  remove={() => removeItemFromCart(cart.product_id)}
                />
              ))}
            </Box>
            <Box w="100%">
              <Summary
                open={() => setShow(!show)}
                handleSubmit={() => {
                  !token ? handleUser() : initializePayment(onSuccess);
                }}
                total={total}
                subTotal={subTotal}
                deliveryFee={deliveryFee}
                canCheckout={canCheckout}
                cart={cart}
                isAddressFilled={isAddressFilled}
                hasMatch={hasMatch}
                taxes={taxes}
              />
            </Box>
          </Box>
        ) : (
          <Box w="100%" h="60vh" display="grid" placeItems="center">
            <Box>
              <Text
                textAlign="center"
                fontWeight={"700"}
                fontSize="20px"
                mb="16px"
              >
                Your cart is empty
              </Text>

              <Center>
                <Link to="/shop">
                  <Button
                    bg="#000"
                    color="#fff"
                    borderRadius="2px"
                    _hover={{
                      background: "#000",
                    }}
                  >
                    Start shopping
                  </Button>
                </Link>
              </Center>
            </Box>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Cart;
