import React from "react";
import { Box, Image, Text, Center, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";
import styles from "./styles.module.css";

const Card = ({ content }) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const current = 0


  return (
    <Link to={`/product/${content.id}`}>
      <Box cursor="pointer">
        <Center>
          <Image
            src={
              Array.isArray(content?.images) && content?.images?.length > 0
                ? content?.images[current]
                : content?.images === null || content?.images?.length === 0
                  ? "https://res.cloudinary.com/alonexx/image/upload/v1720182519/image-1271454_1280_lzhpw7.png"
                  : content?.images
            }
            h={isLargerThan800 ? "363px" : "200px"}
            objectFit="contain"
            borderRadius="12px"
            w="auto"
            transition={"transform 0.3s ease"}
            _hover={{ transform: "scale(1.1)" }}
            maxW="100%"
          />
        </Center>
        <Box className={styles.flex} mt="12px">
          <Box>
            <Text
              color="#171717"
              fontSize="18px"
              fontWeight="500"
              noOfLines={1}
              textAlign="left"
              textTransform={"capitalize"}
            >
              {content.name}
            </Text>
            <Text
              fontWeight="500"
              fontSize="18px"
              color="#1E1E1E80"
              textAlign="left"
            >
              {content.currency}
              {commaNumber(content.price)}
            </Text>
          </Box>

          {content.quantity <= 0 && (
            <Box display="inline-block">
              <Box
                display="grid"
                placeItems="center"
                bg="#FCD1D1"
                borderRadius={"4px"}
                color="#EE1717"
                fontSize={"12px"}
                p="4px"
                fontWeight={"500"}
              >
                Out of Stock
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default Card;
