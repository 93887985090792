import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Box } from "@chakra-ui/react";
import styles from "./styles.module.css";
import MobileNavbar from "../components/navbar/mobileNavbar";

const Layout = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDir={"column"}
      maxH="100vh"
      h="auto"
      position="relative"
    >
      <Box className={styles.mobile}>
        <MobileNavbar />
      </Box>
      <Box className={styles.large_screen}>
        <Navbar />
      </Box>
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
};

export default Layout;
