import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Container,
  Input,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCreatingCustomer } from "../../redux/selectors";
import { login_customer } from "../../redux/reducer";

const Login = () => {
  const [show, setShow] = useState(false);
  const loading = useSelector(getCreatingCustomer);
  const dispatch = useDispatch();

  let data = {
    email_address: "",
    password: "",
  };
  const handleSubmit = (doc) => {
    dispatch(login_customer(doc));
  };
  return (
    <Box bg="#fff">
      <Box p="10px">
        <Link to="/" style={{ textDecoration: "none" }}>
          Home
        </Link>
      </Box>
      <Container
        maxW="container.xl"
        h="100vh"
        display="grid"
        placeItems="center"
      >
        <Box className={styles.box}>
          <Text color="#333333" fontSize="36px" fontWeight="700">
            Log in
          </Text>
          <Text mb="32px" color="#4F4F4F" fontSize="14px" fontWeight="400">
            Welcome back! Please enter your details.
          </Text>
          <Formik initialValues={data} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <FormControl isRequired>
                  <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                    Email
                  </FormLabel>
                  <Input
                    type="email"
                    onChange={handleChange}
                    value={values.email_address}
                    name="email_address"
                    border="1px solid #E0E0E0"
                    borderRadius={"5px"}
                    h="43px"
                    placeholder="Enter your email"
                    _placeholder={{
                      color: "#E0E0E0",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  />
                </FormControl>

                <FormControl mt="20px" isRequired>
                  <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      type={show ? "text" : "password"}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      border="1px solid #E0E0E0"
                      borderRadius={"5px"}
                      h="43px"
                      placeholder="********"
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                    <InputRightElement>
                      {show ? (
                        <AiOutlineEyeInvisible
                          size={20}
                          cursor="pointer"
                          onClick={() => setShow(!show)}
                        />
                      ) : (
                        <AiOutlineEye
                          size={20}
                          cursor="pointer"
                          onClick={() => setShow(!show)}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Link to="/forgot_password" style={{ textDecoration: "none" }}>
                  <Text
                    mt="24px"
                    fontSize="14px"
                    fontWeight="700"
                    textAlign="right"
                    cursor="pointer"
                  >
                    Forgot password?
                  </Text>
                </Link>

                <Button
                  color="#fff"
                  bg="#000000"
                  type="submit"
                  isLoading={loading}
                  disabled={loading}
                  w="100%"
                  borderRadius={"5px"}
                  h="43px"
                  mt="24px"
                  mb="32px"
                  _hover={{
                    background: "#000",
                  }}
                >
                  Sign in
                </Button>

                <Text
                  textAlign="center"
                  color="#4F4F4F"
                  fontSize="14px"
                  fontWeight="400"
                >
                  Don’t have an account?
                  <Link to="/sign-up" className={styles.link}>
                    Sign up
                  </Link>
                </Text>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
