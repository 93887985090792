import React from "react";
import { Box } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import styles from "./styles.module.css";

const Pagination = ({ pageCount, changePage, selected }) => {
  return (
    <Box mt="32px">
      <Box>
        <ReactPaginate
          previousLabel={"PREV"}
          nextLabel={"NEXT"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={styles.pagination}
          activeClassName={styles.active}
          previousLinkClassName={styles.pagination_link}
          nextLinkClassName={styles.pagination_link}
          forcePage={selected}
          renderOnZeroPageCount={false}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
