import React from "react";
import Layout from "../../../layout";
import { Container, Text } from "@chakra-ui/react";

const Shipping = () => {
  return (
    <Layout>
      <Container maxW="container.md" pb="100px">
        <Text
          color="#000"
          fontSize="32px"
          fontWeight="500"
          textAlign="center"
          p="48px"
        >
          Shipping & Returns
        </Text>

        <Text mb="8px" fontSize="16px" fontWeight="600" color="#000">
          Shipping Policy
        </Text>
        <Text fontSize="14px" fontWeight="400" color="#000" textAlign="justify">
          I’m a shipping policy section. I’m a great place to update your
          customers about your shipping methods, packaging and costs. Use plain,
          straightforward language to build trust and make sure that your
          customers stay loyal! <br /> <br /> I'm the second paragraph in your
          shipping policy section. Click here to add your own text and edit me.
          It’s easy. Just click “Edit Text” or double click me to add details
          about your policy and make changes to the font. I’m a great place for
          you to tell a story and let your users know a little more about you.
        </Text>

        <Text mb="8px" mt="24px" fontSize="16px" fontWeight="600" color="#000">
          Return & Exchange Policy
        </Text>
        <Text fontSize="14px" fontWeight="400" color="#000" textAlign="justify">
          I’m a return policy section. I’m a great place to let your customers
          know what to do in case they’ve changed their mind about their
          purchase, or if they’re dissatisfied with a product. Having a
          straightforward refund or exchange policy is a great way to build
          trust and reassure your customers that they can buy with confidence.
          <br /> <br />
          I'm the second paragraph in your return & exchange policy. Click here
          to add your own text and edit me. It’s easy. Just click “Edit Text” or
          double click me to add details about your policy and make changes to
          the font. I’m a great place for you to tell a story and let your users
          know a little more about you.
        </Text>
      </Container>
    </Layout>
  );
};

export default Shipping;
