import React from "react";
import { Box, Text, CloseButton, Icon } from "@chakra-ui/react";
import { NavLink, Link } from "react-router-dom";
import styles from "./styles.module.css";
import { HiOutlineShoppingBag } from "react-icons/hi";

const Mobile = ({ show, onClose, data, cart }) => {
  return (
    <div>
      {" "}
      <Box
        bg="black"
        opacity="0.5"
        position="fixed"
        top="0"
        w="100%"
        h="100vh"
        onClick={onClose}
        left={show ? "0" : "-100%"}
        zIndex="9"
        transition="0.3s ease-in-out"
      />
      <Box
        position="fixed"
        top="0"
        w="300px"
        h="100vh"
        left={show ? "0" : "-100%"}
        zIndex="10"
        bg="#fff"
        opacity={show ? "100%" : "0"}
        transition="0.3s ease-in-out"
        padding="24px"
      >
        <Box display="flex" justifyContent="flex-end" mb="30px">
          <CloseButton onClick={onClose} size="md" />
        </Box>

        <Box display="flex" flexDir="column" gap="48px">
          {data.map((data, i) => (
            <NavLink
              to={data.route}
              key={i}
              className={({ isActive }) =>
                isActive ? styles.link_active : styles.link
              }
            >
              {data.name}
            </NavLink>
          ))}
        </Box>

        <Link to="/cart">
          <Box position="relative">
            {cart?.length >= 1 && (
              <Box
                position="absolute"
                bg="#000"
                top="-2"
                left="-3"
                zIndex="2"
                w="20px"
                h="20px"
                display="grid"
                placeItems="center"
                borderRadius="full"
                color="#fff"
                fontSize="12px"
              >
                {cart?.length}
              </Box>
            )}
            <Box display="flex" alignItems="center" gap="10px" mt="16px">
              <Icon
                as={HiOutlineShoppingBag}
                fontSize="20px"
                color={
                  window.location.pathname === "/cart" ? "#171717" : "#1e1e1e80"
                }
              />
              <Text
                fontWeight={"500"}
                fontSize="18px"
                color={
                  window.location.pathname === "/cart" ? "#171717" : "#1e1e1e80"
                }
              >
                Cart
              </Text>
            </Box>
          </Box>
        </Link>
      </Box>
    </div>
  );
};

export default Mobile;
