import React from "react";
import { Box, Text, Button, Input } from "@chakra-ui/react";
import styles from "../styles.module.css";
import commaNumber from "comma-number";

const Summary = ({
  open,
  cart,
  total,
  handleSubmit,
  isAddressFilled,
  subTotal,
  deliveryFee,
  canCheckout,
  taxes,
}) => {
  return (
    <Box className={styles.aside}>
      <Box
        display="flex"
        flexDir="column"
        gap="8px"
        borderBottom="1px solid #C9C9C9"
        pb="17px"
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Text fontSize="14px" fontWeight="400" textAlign="left">
            Subtotal
          </Text>
          <Text fontSize="14px" fontWeight="400" textAlign="right">
            N{commaNumber(subTotal)}
          </Text>
        </Box>
        {taxes.length >= 1 && (
          <div>
            {taxes.map((tax) => (
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                key={tax.id}
              >
                <Text fontSize="14px" fontWeight="400" textAlign="left">
                  {tax.taxName}
                </Text>
                <Text fontSize="14px" fontWeight="400" textAlign="right">
                  N{commaNumber(tax.taxAmount)}
                </Text>
              </Box>
            ))}
          </div>
        )}

        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box display="flex" gap="3px">
            <Text
              fontSize="14px"
              fontWeight="400"
              textAlign="left"
              whiteSpace={"nowrap"}
            >
              Delivery Fee
            </Text>
            {isAddressFilled && (
              <Text
                textDecor={"underline"}
                fontSize="14px"
                fontWeight="400"
                textAlign="left"
                whiteSpace={"nowrap"}
                cursor="pointer"
                onClick={open}
              >
                (Add Location)
              </Text>
            )}
          </Box>

          <Text fontSize="14px" fontWeight="400" textAlign="right">
            N{commaNumber(deliveryFee)}
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        mt="15px"
      >
        <Text fontSize="14px" fontWeight="600" textAlign="left">
          Total ({cart.length} item)
        </Text>
        <Text fontSize="14px" fontWeight="600" textAlign="right">
          N{commaNumber(total)}
        </Text>
      </Box>

      <Box
        display="flex"
        mt="41px"
        borderRadius={"5px"}
        border="1px solid #C9C9C9"
      >
        <Input
          type="text"
          p="13px 9px"
          placeholder="Enter Coupon Code"
          border="none"
          borderRadius={"5px"}
          outline="none"
        />
        <Button
          bg="#171717"
          color="#fff"
          border="none"
          outline="none"
          borderRightRadius={"5px"}
          borderLeftRadius="0"
          _hover={{
            background: "#171717",
          }}
        >
          Apply
        </Button>
      </Box>

      <Button
        bg="#171717"
        color="#fff"
        w="100%"
        borderRadius="2px"
        isDisabled={!canCheckout()}
        onClick={handleSubmit}
        mt="24px"
        _hover={{
          background: "#171717",
        }}
      >
        Checkout
      </Button>
    </Box>
  );
};

export default Summary;
