import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../layout";
import {
  Box,
  Text,
  Container,
  Image,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import styles from "./styles.module.css";
import { useParams, Link } from "react-router-dom";
import Info from "./components/info";
import { useDispatch, useSelector } from "react-redux";
import {
  get_single_product,
  add_to_cart,
  get_store,
  get_shipping,
  set_user_shipping,
  get_customer,
} from "../../redux/reducer";
import {
  getCustomer,
  getLoadingProduct,
  getMerchant,
  getProduct,
  getShipping,
  getUserShipping,
} from "../../redux/selectors";
import CartIcon from "../../../components/cartIcon";
import ToastMessage from "../../../components/toast";
import SliderComponent from "./components/slider";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import AccordionInfo from "./components/accordionInfo";

const Product = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingProduct);
  const product = useSelector(getProduct);
  const [images, setImages] = useState(null);
  const [mappedImage, setMappedImages] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const merchant = useSelector(getMerchant);
  const shipping = useSelector(getShipping);
  const user_shipping = useSelector(getUserShipping);
  const customer = useSelector(getCustomer);
  const [hasMatch, setHasMatch] = useState(false);
  const [hasData, setHasData] = useState(false);
  const sliderRef = useRef(null);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  const { id } = params;

  useEffect(() => {
    dispatch(get_single_product(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_customer());
  }, [dispatch]);

  useEffect(() => {
    const result = {
      address: customer.address,
      state: customer.state,
      city: customer.city,
      country: customer.country,
    };

    const allValuesEmpty = Object.values(result).every((value) => !value);
    setHasData(allValuesEmpty);
  }, [customer]);

  useEffect(() => {
    if (merchant?.id) {
      dispatch(get_shipping(merchant?.id));
    }
  }, [dispatch, merchant]);

  useEffect(() => {
    const filteredShipping = shipping.some((data) => {
      const stateData = data.states.some(
        (state) => state === `${customer.state} State`
      );
      return stateData;
    });
    setHasMatch(!filteredShipping);
  }, [customer, shipping]);

  useEffect(() => {
    if (typeof product?.images === "string") {
      const array = product?.images?.split(",");
      setImages(array[0]);
      setMappedImages(array);
    } else if (Array.isArray(product?.images) && product.images.length > 0) {
      setImages(product.images[0]);
      setMappedImages(product.images);
    } else {
      const fallbackUrl =
        "https://res.cloudinary.com/alonexx/image/upload/v1720182519/image-1271454_1280_lzhpw7.png";
      setImages(fallbackUrl);
    }
  }, [product]);

  const addToCart = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomNum = Math.random().toString(36).substr(2, 5);
    const id = timestamp + randomNum;
    let data = {
      product_id: id,
      size: size || "",
      id: product.id,
      currency: product.currency,
      price: product.price,
      VAT: product.VAT,
      color: color || "",
      images: product.images,
      name: product.name,
      quantity: parseInt(quantity) || 1,
      base_quantity: product.quantity,
    };
    dispatch(add_to_cart(data));
  };

  const handleQuantity = (type) => {
    if (type === "Increment" && quantity < product.quantity) {
      setQuantity(quantity + 1);
    } else if (type === "Decrement" && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleSize = (doc) => {
    setSize(doc);
  };

  const handleColor = (doc) => {
    setColor(doc);
  };

  const handleShipping = (doc) => {
    dispatch(set_user_shipping(doc));
  };

  const handleSlider = (direction) => {
    const container = sliderRef.current;

    if (container) {
      if (direction === "left") {
        container.scrollLeft -= 100;
      } else if (direction === "right") {
        container.scrollLeft += 100;
      }
    }
  };

  return (
    <>
      {loading ? (
        <Box w="100%" h="100vh" display="grid" placeItems="center">
          <Spinner size="xl" color="#000" />
        </Box>
      ) : (
        <Layout>
          {hasData ? (
            <ToastMessage
              show={hasData && Object.keys(customer).length >= 1}
              onClose={() => setHasData(false)}
            >
              <Text>
                Please add your address{" "}
                <Link to="/profile" style={{ textDecoration: "underline" }}>
                  Go to Profile
                </Link>
              </Text>
            </ToastMessage>
          ) : (
            <ToastMessage
              show={hasMatch && Object.keys(customer)?.length >= 1}
              onClose={() => setHasMatch(false)}
              type="error"
            >
              Seems we do not deliver to your state
            </ToastMessage>
          )}

          <Container maxW="container.lg" pb="100px" pt="64px">
            <CartIcon />
            <Box display="flex" gap="11px" alignItems="center">
              <Link to="/shop" style={{ textDecoration: "none" }}>
                <Text fontSize="16px" fontWeight="400">
                  Shop
                </Text>
              </Link>

              <Text>-</Text>
              <Text
                color="#707070"
                fontWeight="400"
                fontSize="16px"
                textTransform="capitalize"
              >
                {product?.name}
              </Text>
            </Box>

            <Box className={styles.grid}>
              <div>
                <Box>
                  <Image
                    src={images}
                    borderRadius="5px"
                    w="100%"
                    h="auto"
                    objectFit="center"
                  />
                </Box>
                <Box display="flex" gap="16px" mt="24px">
                  {mappedImage?.map((data, idx) => (
                    <Box
                      key={idx}
                      cursor="pointer"
                      p="7px"
                      onClick={() => setImages(data)}
                      border={
                        images === data
                          ? "1.5px solid #2B292F"
                          : "1px solid #C9C9C9"
                      }
                      borderRadius="5px"
                      w="80px"
                    >
                      <Image src={data} objectFit="cover" alt={data} />
                    </Box>
                  ))}
                </Box>
              </div>

              <Box>
                <Info
                  product={product}
                  handleQuantity={handleQuantity}
                  addToCart={addToCart}
                  handleSize={handleSize}
                  handleColor={handleColor}
                  size={size}
                  color={color}
                  quantity={quantity}
                  shipping={shipping}
                  user_shipping={user_shipping}
                  handleShipping={handleShipping}
                />
                {shipping?.length >= 1 && (
                  <AccordionInfo merchant_shipping={shipping} />
                )}
              </Box>
            </Box>

            <Box mt="90px">
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                mb="20px"
              >
                <Text fontSize="20px" fontWeight={"700"}>
                  Similar Products
                </Text>

                <Box display="flex" gap="10px" alignItems={"center"}>
                  <IconButton
                    icon={<BsArrowLeft />}
                    onClick={() => handleSlider("left")}
                    border="1px solid #171717"
                    borderRadius={"full"}
                    w="20px"
                  />
                  <IconButton
                    icon={<BsArrowRight />}
                    onClick={() => handleSlider("right")}
                    border="1px solid #171717"
                    borderRadius={"full"}
                    w="20px"
                  />
                </Box>
              </Box>

              <SliderComponent single={product} slide={sliderRef} />
            </Box>
          </Container>
        </Layout>
      )}
    </>
  );
};

export default Product;
