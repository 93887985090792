// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_box__NkmJj {\n  width: 360px;\n}\n\n.styles_link__6KJSK {\n  text-decoration: none;\n  font-size: 14px;\n  font-weight: 500;\n  margin-left: 2px;\n  color: #000;\n}\n\n.styles_container__zdn3z {\n  border: 1px solid #e0e0e0;\n  height: 43px;\n  border-radius: 5px;\n  padding-left: 10px;\n}\n\n.styles_container__zdn3z input {\n  height: 43px;\n  border: none;\n  background-color: transparent;\n}\n\n.styles_container__zdn3z input:focus {\n  border: none;\n}\n\n/* mobile */\n@media (max-width: 640px) {\n  .styles_box__NkmJj {\n    width: 100%;\n  }\n\n  .styles_link__6KJSK {\n    text-decoration: none;\n    font-size: 14px;\n    font-weight: 500;\n    margin-left: 2px;\n    color: #000;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/template1/signup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA,WAAW;AACX;EACE;IACE,WAAW;EACb;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".box {\n  width: 360px;\n}\n\n.link {\n  text-decoration: none;\n  font-size: 14px;\n  font-weight: 500;\n  margin-left: 2px;\n  color: #000;\n}\n\n.container {\n  border: 1px solid #e0e0e0;\n  height: 43px;\n  border-radius: 5px;\n  padding-left: 10px;\n}\n\n.container input {\n  height: 43px;\n  border: none;\n  background-color: transparent;\n}\n\n.container input:focus {\n  border: none;\n}\n\n/* mobile */\n@media (max-width: 640px) {\n  .box {\n    width: 100%;\n  }\n\n  .link {\n    text-decoration: none;\n    font-size: 14px;\n    font-weight: 500;\n    margin-left: 2px;\n    color: #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "styles_box__NkmJj",
	"link": "styles_link__6KJSK",
	"container": "styles_container__zdn3z"
};
export default ___CSS_LOADER_EXPORT___;
