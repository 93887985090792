import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import styles from "./styles.module.css";
import { IoClose } from "react-icons/io5";
import question from "../../assets/question.png";
import fail from "../../assets/fail.png";

const ToastMessage = ({ type, children, show, onClose }) => {
  return (
    <Box>
      {type === "error" ? (
        <Box
          position="fixed"
          bottom="0"
          right={show ? "0" : "-200%"}
          transition="0.3s ease-in-out"
          zIndex={500}
          mr="10px"
          mb="10px"
        >
          <Box className={styles.error}>
            <Image src={fail} alt="fail" className={styles.image} />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Text className={styles.large_text}>Oh snap!</Text>
                <Text className={styles.sub_text}>{children}</Text>
              </Box>
              <Box onClick={onClose} cursor="pointer">
                <IoClose size={30} color="#fff" />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          position="fixed"
          bottom="0"
          right={show ? "0" : "-200%"}
          transition="0.3s ease-in-out"
          zIndex={500}
          mr="10px"
          mb="10px"
        >
          <Box className={styles.base}>
            <Image src={question} alt="question" className={styles.image} />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Text className={styles.large_text}>Hi there!</Text>
                <Text className={styles.sub_text}>{children}</Text>
              </Box>
              <Box onClick={onClose} cursor="pointer">
                <IoClose size={30} color="#fff" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ToastMessage;
