// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_table_head__IhhLO th {\n  border-bottom: none;\n  background-color: #d0d5dd;\n}\n\n.styles_table_body__Yi3ob td {\n  border-bottom: 1px solid #f9f9f9;\n  padding: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/template1/profile/components/order/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,aAAa;AACf","sourcesContent":[".table_head th {\n  border-bottom: none;\n  background-color: #d0d5dd;\n}\n\n.table_body td {\n  border-bottom: 1px solid #f9f9f9;\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_head": "styles_table_head__IhhLO",
	"table_body": "styles_table_body__Yi3ob"
};
export default ___CSS_LOADER_EXPORT___;
