import React from "react";
import { Box, Container, Text } from "@chakra-ui/react";
import Layout from "../../../layout";
import styles from "./styles.module.css";
import Forms from "./components/form";

const Contact = () => {
  return (
    <Layout>
      <Container maxW="container.xl" pb="100px">
        <Text
          textAlign="center"
          textTransform="capitalize"
          fontWeight="700"
          fontSize="20px"
          pt="48px"
          pb="48px"
        >
          Contact
        </Text>
        <Box className={styles.grid}>
          <Box display="grid" placeItems="center">
            <Box>
              <Text textAlign="center" fontSize="24px" fontWeight="500">
                You're welcome to contact us with any inquiry
              </Text>

              <Text
                textAlign="center"
                fontSize="16px"
                fontWeight="400"
                mt="40px"
              >
                Phone Number: +23470234567890
              </Text>

              <Text
                textAlign="center"
                fontSize="16px"
                fontWeight="400"
                mt="40px"
              >
                Email Address: info@plexus.retailloop.co
              </Text>
            </Box>
          </Box>
          <Forms />
        </Box>
      </Container>
    </Layout>
  );
};

export default Contact;
