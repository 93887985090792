import React from "react";
import Layout from "../../../layout";
import { Container, Text, Box } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const Faqs = () => {
  return (
    <Layout>
      <Container maxW="container.md" pb="100px">
        <Text
          color="#000"
          fontSize="32px"
          fontWeight="500"
          textAlign="center"
          p="48px"
        >
          Frequently Asked Questions
        </Text>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton _hover={{ background: "none" }}>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize="20px"
                  fontWeight="500"
                >
                  What is an FAQ section?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              color="#707070"
              fontSize="14px"
              fontWeight="400"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Layout>
  );
};

export default Faqs;
