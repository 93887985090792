export const ng_states = [
  {
    minLat: 4.810874,
    name: "Abia",
    capital: "Umuahia",
    latitude: 5.430892099999999,
    minLong: 7.150823,
    maxLat: 6.0191921,
    longitude: 7.524724300000001,
    maxLong: 7.9630091,
    alias: "abia",
    cities: {
      message: "No cities found for 'abia'",
      status: 404,
    },
    lgas: [
      "Aba North",
      "Arochukwu",
      "Aba South",
      "Bende",
      "Isiala Ngwa North",
      "Ikwuano",
      "Isiala Ngwa South",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohafia",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu Nneochi",
    ],
  },
  {
    minLat: 7.452592,
    name: "Adamawa",
    capital: "Yola",
    latitude: 9.3250497,
    minLong: 11.392936,
    maxLat: 10.943588,
    longitude: 12.4380581,
    maxLong: 13.6924919,
    alias: "adamawa",
    cities: {
      message: "No cities found for 'adamawa'",
      status: 404,
    },
    lgas: [
      "Demsa",
      "Fufure",
      "Ganye",
      "Gayuk",
      "Gombi",
      "Grie",
      "Hong",
      "Jada",
      "Larmurde",
      "Madagali",
      "Maiha",
      "Mayo Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    minLat: 4.4780049,
    name: "Akwa Ibom",
    capital: "Uyo",
    latitude: 4.929986899999999,
    minLong: 7.4605409,
    maxLat: 5.530245,
    longitude: 7.872159999999999,
    maxLong: 8.340371,
    alias: "akwa_ibom",
    cities: ["Eket", "Ikot Ekpene", "Oron", "Uyo"],
    lgas: [
      "Abak",
      "Eastern Obolo",
      "Eket",
      "Esit Eket",
      "Essien Udim",
      "Etim Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono-Ibom",
      "Ikot Abasi",
      "Ika",
      "Ikono",
      "Ikot Ekpene",
      "Ini",
      "Mkpat-Enin",
      "Itu",
      "Mbo",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Udung-Uko",
      "Ukanafun",
      "Oruk Anam",
      "Uruan",
      "Urue-Offong/Oruko",
      "Uyo",
    ],
  },
  {
    minLat: 5.692615000000001,
    name: "Anambra",
    capital: "Awka",
    latitude: 6.2757656,
    minLong: 6.613086,
    maxLat: 6.7795999,
    longitude: 7.0068393,
    maxLong: 7.355934,
    alias: "anambra",
    cities: {
      message: "No cities found for 'anambra'",
      status: 404,
    },
    lgas: [
      "Aguata",
      "Anambra East",
      "Anaocha",
      "Awka North",
      "Anambra West",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili South",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oyi",
    ],
  },
  {
    minLat: 10.255381,
    name: "Bauchi",
    capital: "Bauchi",
    latitude: 10.315833,
    minLong: 9.779892,
    maxLat: 10.3398292,
    longitude: 9.844166999999999,
    maxLong: 9.864006,
    alias: "bauchi",
    cities: ["Bauchi"],
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    minLat: 6.4427789,
    name: "Benue",
    capital: "Makurdi",
    latitude: 7.350825899999999,
    minLong: 7.4893301,
    maxLat: 8.149299,
    longitude: 8.8362755,
    maxLong: 9.918301,
    alias: "benue",
    cities: ["Makurdi"],
    lgas: [
      "Agatu",
      "Apa",
      "Ado",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Oturkpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    minLat: 10.0291549,
    name: "Borno",
    capital: "Maiduguri",
    latitude: 11.5097479,
    minLong: 11.6286559,
    maxLat: 13.7144441,
    longitude: 12.9789121,
    maxLong: 14.680073,
    alias: "borno",
    cities: ["Bama", "Maiduguri"],
    lgas: [
      "Abadam",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Guzamala",
      "Gubio",
      "Hawul",
      "Gwoza",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Mobbar",
      "Marte",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    minLat: 4.2771439,
    name: "Bayelsa",
    capital: "Yenagoa",
    latitude: 4.867776699999999,
    minLong: 5.370120500000001,
    maxLat: 5.3933139,
    longitude: 5.898713900000001,
    maxLong: 6.724865899999999,
    alias: "bayelsa",
    cities: ["Yenagoa"],
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenagoa",
    ],
  },
  {
    minLat: 4.690596000000001,
    name: "Cross River",
    capital: "Calabar",
    latitude: 6.167031499999999,
    minLong: 7.863165999999999,
    maxLat: 6.899680999999999,
    longitude: 8.6600586,
    maxLong: 9.472486,
    alias: "cross_river",
    cities: {
      message: "No cities found for 'cross_river'",
      status: 404,
    },
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakuur",
      "Yala",
    ],
  },
  {
    minLat: 5.024351999999999,
    name: "Delta",
    capital: "Asaba",
    latitude: 5.5324624,
    minLong: 5.000000099999999,
    maxLat: 6.5248195,
    longitude: 5.898713900000001,
    maxLong: 6.7653911,
    alias: "delta",
    cities: ["Sapele"],
    lgas: [
      "Aniocha North",
      "Aniocha South",
      "Bomadi",
      "Burutu",
      "Ethiope West",
      "Ethiope East",
      "Ika North East",
      "Ika South",
      "Isoko North",
      "Isoko South",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli North",
      "Ukwuani",
      "Ughelli South",
      "Uvwie",
      "Warri North",
      "Warri South",
      "Warri South West",
    ],
  },
  {
    minLat: 5.6873539,
    name: "Ebonyi",
    capital: "Abakaliki",
    latitude: 6.177973,
    minLong: 7.5251131,
    maxLat: 6.807093,
    longitude: 7.959286299999999,
    maxLong: 8.4470269,
    alias: "ebonyi",
    cities: ["Abakaliki"],
    lgas: [
      "Abakaliki",
      "Afikpo North",
      "Ebonyi",
      "Afikpo South",
      "Ezza North",
      "Ikwo",
      "Ezza South",
      "Ivo",
      "Ishielu",
      "Izzi",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    minLat: 5.7386799,
    name: "Edo",
    capital: "Benin",
    latitude: 6.5438101,
    minLong: 4.975229,
    maxLat: 7.5721479,
    longitude: 5.898713900000001,
    maxLong: 6.707891000000001,
    alias: "edo",
    cities: [
      "Auchi",
      "Aviele",
      "Afuze",
      "Benin",
      "Bode",
      "Ehor",
      "Ekpoma",
      "Ewu",
      "Fugar",
      "Ibilo",
      "Igarra",
      "Igueben",
      "Irrua",
      "Iyamoh",
      "Jattu",
      "Okpella",
      "Ora",
      "Uromi",
    ],
    lgas: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba Okha",
      "Orhionmwon",
      "Oredo",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
  },
  {
    minLat: 7.272215999999999,
    name: "Ekiti",
    capital: "Ado-Ekiti",
    latitude: 7.665581299999999,
    minLong: 4.9103081,
    maxLat: 8.0674911,
    longitude: 5.3102505,
    maxLong: 5.8048959,
    alias: "ekiti",
    cities: ["Ado Ekiti"],
    lgas: [
      "Ado Ekiti",
      "Efon",
      "Ekiti East",
      "Ekiti South-West",
      "Ekiti West",
      "Emure",
      "Gbonyin",
      "Ido Osi",
      "Ijero",
      "Ikere",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ikole",
      "Ise/Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    minLat: 6.360852,
    name: "Enugu",
    capital: "Enugu",
    latitude: 6.452667,
    minLong: 7.458772700000001,
    maxLat: 6.5155669,
    longitude: 7.510332999999998,
    maxLong: 7.618160199999999,
    alias: "enugu",
    cities: ["Enugu", "Nsukka", "Agbani", "Awgu", "Udi", "Oji-River"],
    lgas: [
      "Awgu",
      "Aninri",
      "Enugu East",
      "Enugu North",
      "Ezeagu",
      "Enugu South",
      "Igbo Etiti",
      "Igbo Eze North",
      "Igbo Eze South",
      "Isi Uzo",
      "Nkanu East",
      "Nkanu West",
      "Nsukka",
      "Udenu",
      "Oji River",
      "Uzo Uwani",
      "Udi",
    ],
  },
  {
    minLat: 8.396675,
    name: "Federal Capital Territory",
    capital: "Abuja",
    latitude: 8.8556838,
    minLong: 6.749135,
    maxLat: 9.3574219,
    longitude: 7.179025999999999,
    maxLong: 7.617400000000001,
    alias: "abuja",
    cities: {
      message: "No cities found for 'abuja'",
      status: 404,
    },
    lgas: [
      "Abaji",
      "Bwari",
      "Gwagwalada",
      "Kuje",
      "Kwali",
      "Municipal Area Council",
    ],
  },
  {
    minLat: 10.264117,
    name: "Gombe",
    capital: "Gombe",
    latitude: 10.283333,
    minLong: 11.1424112,
    maxLat: 10.3128497,
    longitude: 11.166667,
    maxLong: 11.2147019,
    alias: "gombe",
    cities: ["Gombe"],
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    minLat: 12.5688205,
    name: "Jigawa",
    capital: "Dutse",
    latitude: 12.5700315,
    minLong: 8.9387779,
    maxLat: 12.5708993,
    longitude: 8.9400589,
    maxLong: 8.9410364,
    alias: "jigawa",
    cities: ["Dutse"],
    lgas: [
      "Auyo",
      "Babura",
      "Buji",
      "Biriniwa",
      "Birnin Kudu",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kazaure",
      "Kiri Kasama",
      "Kiyawa",
      "Kaugama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Sule Tankarkar",
      "Roni",
      "Ringim",
      "Yankwashi",
      "Taura",
    ],
  },
  {
    minLat: 5.179824000000001,
    name: "Imo",
    capital: "Owerri",
    latitude: 5.5214533,
    minLong: 6.622309899999999,
    maxLat: 5.928465,
    longitude: 6.920913499999999,
    maxLong: 7.404109,
    alias: "imo",
    cities: ["Okigwe", "Orlu"],
    lgas: [
      "Aboh Mbaise",
      "Ahiazu Mbaise",
      "Ehime Mbano",
      "Ezinihitte",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala Mbano",
      "Mbaitoli",
      "Isu",
      "Ngor Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri Municipal",
      "Owerri North",
      "Unuimo",
      "Owerri West",
    ],
  },
  {
    minLat: 10.3971566,
    name: "Kaduna",
    capital: "Kaduna",
    latitude: 10.516667,
    minLong: 7.349789099999999,
    maxLat: 10.6169963,
    longitude: 7.433332999999999,
    maxLong: 7.508812000000001,
    alias: "kaduna",
    cities: ["Kaduna", "Zaria"],
    lgas: [
      "Birnin Gwari",
      "Chikun",
      "Giwa",
      "Ikara",
      "Igabi",
      "Jaba",
      "Jema'a",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon Gari",
      "Sanga",
      "Soba",
      "Zangon Kataf",
      "Zaria",
    ],
  },
  {
    minLat: 10.0931591,
    name: "Kebbi",
    capital: "Birnin Kebbi",
    latitude: 11.6781241,
    minLong: 3.4958741,
    maxLat: 13.232542,
    longitude: 4.0695454,
    maxLong: 6.027123,
    alias: "kebbi",
    cities: ["Birnin Kebbi"],
    lgas: [
      "Aleiro",
      "Argungu",
      "Arewa Dandi",
      "Augie",
      "Bagudo",
      "Birnin Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko/Besse",
      "Maiyama",
      "Ngaski",
      "Shanga",
      "Suru",
      "Sakaba",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    minLat: 11.912873,
    name: "Kano",
    capital: "Kano",
    latitude: 12.0021794,
    minLong: 8.411321599999999,
    maxLat: 12.0829016,
    longitude: 8.591956099999999,
    maxLong: 8.6704765,
    alias: "kano",
    cities: {
      message: "No cities found for 'kano'",
      status: 404,
    },
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun Mallam",
      "Gezawa",
      "Gaya",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Takai",
      "Sumaila",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    minLat: 6.528274199999999,
    name: "Kogi",
    capital: "Lokoja",
    latitude: 7.561891,
    minLong: 5.340376,
    maxLat: 8.7320238,
    longitude: 6.5783387,
    maxLong: 7.8822179,
    alias: "kogi",
    cities: ["Lokoja", "Okene"],
    lgas: [
      "Ajaokuta",
      "Adavi",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela Odolu",
      "Ijumu",
      "Kogi",
      "Kabba/Bunu",
      "Lokoja",
      "Ofu",
      "Mopa Muro",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
  },
  {
    minLat: 6.3936419,
    name: "Lagos",
    capital: "Ikeja",
    latitude: 6.5243793,
    minLong: 3.0982732,
    maxLat: 6.7027984,
    longitude: 3.3792057,
    maxLong: 3.696727799999999,
    alias: "lagos",
    cities: ["Agege", "Ikeja", "Ikoyi", "Yaba", "Berger", "Ojuelegba", "Lekki"],
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Badagry",
      "Apapa",
      "Epe",
      "Eti Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Mushin",
      "Lagos Mainland",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere Lagos State",
    ],
  },
  {
    minLat: 12.934095,
    name: "Katsina",
    capital: "Katsina",
    latitude: 12.983333,
    minLong: 7.5706298,
    maxLat: 13.0357094,
    longitude: 7.6,
    maxLong: 7.676266000000001,
    alias: "katsina",
    cities: ["Funtua", "Katsina"],
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Danja",
      "Dandume",
      "Dan Musa",
      "Daura",
      "Dutsi",
      "Dutsin Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai'Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    minLat: 7.966079000000001,
    name: "Kwara",
    capital: "Ilorin",
    latitude: 8.9847995,
    minLong: 2.728413,
    maxLat: 10.152084,
    longitude: 4.5624426,
    maxLong: 6.2142591,
    alias: "kwara",
    cities: ["Offa", "Ilorin"],
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ilorin East",
      "Ifelodun",
      "Ilorin South",
      "Ekiti Kwara State",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    minLat: 7.769181,
    name: "Nasarawa",
    capital: "Lafia",
    latitude: 8.5705151,
    minLong: 6.924008,
    maxLat: 9.365964000000002,
    longitude: 8.3088441,
    maxLong: 9.605724,
    alias: "nasarawa",
    cities: ["Lafia"],
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Lafia",
      "Kokona",
      "Nasarawa Egon",
      "Nasarawa",
      "Obi",
      "Toto",
      "Wamba",
    ],
  },
  {
    minLat: 4.269857099999999,
    name: "Niger",
    capital: "Minna",
    latitude: 9.081999,
    minLong: 2.676932,
    maxLat: 13.885645,
    longitude: 8.675277,
    maxLong: 14.677982,
    alias: "niger",
    cities: ["Bida", "Suleja"],
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Mariga",
      "Magama",
      "Mokwa",
      "Mashegu",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    minLat: 6.315346099999999,
    name: "Ogun",
    capital: "Abeokuta",
    latitude: 6.9098333,
    minLong: 2.668432,
    maxLat: 7.974634999999999,
    longitude: 3.2583626,
    maxLong: 4.5990951,
    alias: "ogun",
    cities: ["Ijebu Ode", "Shagamu"],
    lgas: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Egbado North",
      "Ewekoro",
      "Egbado South",
      "Ijebu North",
      "Ijebu East",
      "Ifo",
      "Ijebu Ode",
      "Ijebu North East",
      "Imeko Afon",
      "Ikenne",
      "Ipokia",
      "Odeda",
      "Obafemi Owode",
      "Odogbolu",
      "Remo North",
      "Ogun Waterside",
      "Shagamu",
    ],
  },
  {
    minLat: 7.0529381,
    name: "Ondo",
    capital: "Akure",
    latitude: 7.083333,
    minLong: 4.7963046,
    maxLat: 7.1249106,
    longitude: 4.833333,
    maxLong: 4.871921599999999,
    alias: "ondo",
    cities: ["Owo"],
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure North",
      "Akure South",
      "Ese Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Irele",
      "Ile Oluji/Okeigbo",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ose",
      "Ondo East",
      "Owo",
    ],
  },
  {
    minLat: 4.347035099999999,
    name: "Rivers",
    capital: "Port Harcourt",
    latitude: 4.8580767,
    minLong: 6.382472000000001,
    maxLat: 5.694652899999999,
    longitude: 6.920913499999999,
    maxLong: 7.591592899999998,
    alias: "rivers",
    cities: ["Port Harcourt"],
    lgas: [
      "Abua/Odual",
      "Ahoada East",
      "Ahoada West",
      "Andoni",
      "Akuku-Toru",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Emuoha",
      "Eleme",
      "Ikwerre",
      "Etche",
      "Gokana",
      "Khana",
      "Obio/Akpor",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port Harcourt",
      "Tai",
    ],
  },
  {
    minLat: 7.790425099999999,
    name: "Oyo",
    capital: "Ibadan",
    latitude: 7.85,
    minLong: 3.8832377,
    maxLat: 7.8899933,
    longitude: 3.933,
    maxLong: 3.9751624,
    alias: "oyo",
    cities: ["Oyo"],
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibarapa Central",
      "Ibadan South-West",
      "Ibarapa East",
      "Ido",
      "Ibarapa North",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Oluyole",
      "Ona Ara",
      "Orelope",
      "Ori Ire",
      "Oyo",
      "Oyo East",
      "Saki East",
      "Saki West",
      "Surulere Oyo State",
    ],
  },
  {
    minLat: 6.969976,
    name: "Osun",
    capital: "Osogbo",
    latitude: 7.5875843,
    minLong: 4.020612,
    maxLat: 8.088640999999999,
    longitude: 4.5624426,
    maxLong: 5.0647019,
    alias: "osun",
    cities: ["Iwo", "Osogbo"],
    lgas: [
      "Aiyedire",
      "Atakunmosa West",
      "Atakunmosa East",
      "Aiyedaade",
      "Boluwaduro",
      "Boripe",
      "Ife East",
      "Ede South",
      "Ife North",
      "Ede North",
      "Ife South",
      "Ejigbo",
      "Ife Central",
      "Ifedayo",
      "Egbedore",
      "Ila",
      "Ifelodun",
      "Ilesa East",
      "Ilesa West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    minLat: 12.9764148,
    name: "Sokoto",
    capital: "Sokoto",
    latitude: 13.066667,
    minLong: 5.1676084,
    maxLat: 13.0880893,
    longitude: 5.233333,
    maxLong: 5.2881144,
    alias: "sokoto",
    cities: {
      message: "No cities found for 'sokoto'",
      status: 404,
    },
    lgas: [
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Isa",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
      "Binji",
      "Bodinga",
      "Dange Shuni",
      "Goronyo",
      "Gada",
    ],
  },
  {
    minLat: 8.350639,
    name: "Plateau",
    capital: "Jos",
    latitude: 9.2350817,
    minLong: 8.544271,
    maxLat: 10.3447241,
    longitude: 9.7232673,
    maxLong: 10.6606639,
    alias: "plateau",
    cities: {
      message: "No cities found for 'plateau'",
      status: 404,
    },
    lgas: [
      "Bokkos",
      "Barkin Ladi",
      "Bassa",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Kanke",
      "Langtang South",
      "Langtang North",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    minLat: 6.502453999999999,
    name: "Taraba",
    capital: "Jalingo",
    latitude: 7.9868755,
    minLong: 9.1043009,
    maxLat: 9.604444899999999,
    longitude: 10.9807003,
    maxLong: 11.981274,
    alias: "taraba",
    cities: ["Wukari"],
    lgas: [
      "Ardo Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim Lamido",
      "Kumi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    minLat: 10.5932052,
    name: "Yobe",
    capital: "Damaturu",
    latitude: 12.1871412,
    minLong: 9.665973100000002,
    maxLat: 13.376133,
    longitude: 11.7068294,
    maxLong: 12.494047,
    alias: "yobe",
    cities: ["Nguru", "Potiskum"],
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
    ],
  },
  {
    minLat: 10.854616,
    name: "Zamfara",
    capital: "Gusau",
    latitude: 12.1844159,
    minLong: 5.021007099999999,
    maxLat: 13.207291,
    longitude: 6.2375947,
    maxLong: 7.245729999999999,
    alias: "zamfara",
    cities: ["Gusau"],
    lgas: [
      "Anka",
      "Birnin Magaji/Kiyaw",
      "Bakura",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Kaura Namoda",
      "Maradun",
      "Shinkafi",
      "Maru",
      "Talata Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
