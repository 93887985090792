import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import { Text, Button, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { Country, State, City } from "country-state-city";
import InputText from "../../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getUpdating } from "../../../redux/selectors";
import { get_customer, update_customer } from "../../../redux/reducer";
import { ng_states } from "../../../../utils/enums";

const AddShippingLocation = ({ open, close, title }) => {
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState("Nigeria");
  const [states, setStates] = useState([]);
  const [stateCode, setStateCode] = useState("NG");
  const [selectedState, setSelectedState] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [cities, setCities] = useState([]);
  const loading = useSelector(getUpdating);
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);

  useEffect(() => {
    dispatch(get_customer());
  }, [dispatch]);

  let data = {
    address: customer?.address,
    state: customer?.state,
    city: customer?.city,
  };

  useEffect(() => {
    if (stateCode === "NG") {
      setStates(ng_states);
    } else {
      const states = State.getStatesOfCountry(stateCode);
      setStates(states);
    }
  }, [stateCode]);

  useEffect(() => {
    if (cityCode !== "") {
      const city = City.getCitiesOfState(stateCode, cityCode);
      setCities(city);
    }
  }, [cityCode, stateCode]);

  const handleCountry = (e) => {
    let data = JSON.parse(e.target.value);
    if (data.isoCode === "NG") {
      setStates(ng_states);
      setCountry(data.name);
    } else {
      setStateCode(data.isoCode);
      setCountry(data.name);
    }
  };

  const handleState = (e) => {
    const data = JSON.parse(e.target.value);
    if (data?.lgas?.length >= 1) {
      setCities(data.lgas);
      setSelectedState(data.name);
    } else {
      setCityCode(data.isoCode);
      setSelectedState(data.name);
    }
  };

  const handleSubmit = (docs) => {
    let data = {
      ...docs,
      country: country || customer?.country,
      state: selectedState || customer?.state,
    };
    dispatch(update_customer({ data, close, id: customer.id }));
  };
  return (
    <Modal isOpen={open} onClose={close} size="xs">
      <ModalOverlay />
      <ModalContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ModalHeader fontSize="16px" fontWeight="600" whiteSpace="nowrap">
            {title || "Add Shipping Location"}
          </ModalHeader>
          <ModalCloseButton />
        </Box>

        <ModalBody pb="32px">
          <Formik initialValues={data} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <Text fontSize="12px" fontWeight={500} mb="8px">
                  Country
                </Text>
                <Select
                  onChange={handleCountry}
                  border="1px solid #C9C9C9"
                  borderRadius="5px"
                >
                  <option>Choose Country </option>
                  {countries.map((data) => (
                    <option
                      key={data.isoCode}
                      value={JSON.stringify(data)}
                      selected={
                        customer?.country === data.name || country === data.name
                      }
                    >
                      {data.name}
                    </option>
                  ))}
                </Select>

                <Text fontSize="12px" fontWeight={500} mb="8px" mt="16px">
                  State
                </Text>
                <Select
                  onChange={handleState}
                  name="state"
                  border="1px solid #C9C9C9"
                  borderRadius="5px"
                  // value={values.state}
                  required
                >
                  <option>
                    {selectedState ? selectedState : "Choose State"}
                  </option>
                  {states?.map((data) => (
                    <option
                      key={data.isoCode}
                      value={JSON.stringify(data)}
                      selected={
                        data.name === customer?.state ||
                        data.name === values.state
                      }
                    >
                      {data.name}
                    </option>
                  ))}
                </Select>

                <Text fontSize="12px" fontWeight={500} mt="16px" mb="8px">
                  City
                </Text>
                <Select
                  onChange={handleChange}
                  name="city"
                  border="1px solid #C9C9C9"
                  borderRadius="5px"
                  // value={values.city}
                  required
                >
                  <option>Choose City</option>
                  {cities?.map((data) => (
                    <option key={data.latitude} value={data.name || data}>
                      {data.name || data}
                    </option>
                  ))}
                </Select>

                <Text fontSize="12px" fontWeight={500} mt="16px">
                  Address
                </Text>
                <InputText
                  name="address"
                  placeholder="Enter Address"
                  value={values.address}
                  type="text"
                  handleChange={handleChange}
                />

                <Box display="flex" justifyContent={"center"} mt="24px">
                  <Button
                    type="submit"
                    isLoading={loading}
                    disabled={loading}
                    bg="#000"
                    color="#fff"
                    w="288px"
                    borderRadius="2px"
                    fontSize="14px"
                    fontWeight="400"
                    _hover={{
                      background: "#000",
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddShippingLocation;
