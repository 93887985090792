import React from "react";
import { Box, Text, useMediaQuery, IconButton } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";

const SideModal = ({ show, close, children, title }) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  return (
    <div>
      <Box
        bg="black"
        opacity="0.5"
        position="fixed"
        top="0"
        w="100%"
        h="100vh"
        onClick={close}
        right={show ? "0" : "-100%"}
        zIndex="9"
        transition="0.3s ease-in-out"
      />
      <Box
        position="fixed"
        top="0"
        w={isLargerThan800 ? "500px" : "100%"}
        h="100vh"
        right={show ? "0" : "-100%"}
        zIndex="10"
        bg="#fff"
        opacity={show ? "100%" : "0"}
        transition="0.3s ease-in-out"
      >
        <Box h="100vh" overflowY="scroll" padding="24px">
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Text fontSize="30px" fontWeight="600">
              {title}
            </Text>
            <IconButton icon={<AiOutlineClose size={20} onClick={close} />} />
          </Box>
          <Box mt="24px">{children}</Box>
        </Box>
      </Box>
    </div>
  );
};

export default SideModal;
