import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "../../../redux/selectors";
import styles from "../styles.module.css";
import { get_store } from "../../../redux/reducer";
import { Link } from "react-router-dom";
import { Box, Image, Text } from "@chakra-ui/react";
import commaNumber from "comma-number";

const SliderComponent = ({ single, slide }) => {
  const products = useSelector(getStore);
  const dispatch = useDispatch();
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    const updated = products.filter((product) => product.id !== single.id);
    const response = updated.slice(0, 10)
    setUpdatedProducts(response);
  }, [products, single]);
  return (
    <div className={styles.container}>
      <div className={styles.slider} ref={slide}>
        {updatedProducts?.map((product) => (
          <Link to={`/product/${product.id}`}>
            <Box w="304px">
              <Image
                src={
                  Array.isArray(product.images) && product.images.length > 0
                    ? product.images[0]
                    : product.images === null || product.images.length === 0
                    ? "https://res.cloudinary.com/alonexx/image/upload/v1720182519/image-1271454_1280_lzhpw7.png"
                    : product.images
                }
                h="363px"
                objectFit={"contain"}
                transition={"transform 0.3s ease"}
                _hover={{ transform: "scale(1.1)" }}
                w="100%"
              />
              <Box className={styles.flex} mt="12px">
                <Box>
                  <Text
                    color="#171717"
                    fontSize="18px"
                    fontWeight="500"
                    noOfLines={1}
                    textAlign="left"
                    textTransform={"capitalize"}
                  >
                    {product.name}
                  </Text>
                  <Text
                    fontWeight="500"
                    fontSize="18px"
                    color="#1E1E1E80"
                    textAlign="left"
                  >
                    {product.currency}
                    {commaNumber(product.price)}
                  </Text>
                </Box>
                {product.quantity <= 0 && (
                  <Box display="inline-block">
                    <Box
                      display="grid"
                      placeItems="center"
                      bg="#FCD1D1"
                      borderRadius={"4px"}
                      color="#EE1717"
                      fontSize={"12px"}
                      p="4px"
                      fontWeight={"500"}
                    >
                      Out of Stock
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SliderComponent;
