// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_flex__p-Vyr {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n@media (max-width: 640px) {\n  .styles_flex__p-Vyr {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    justify-content: space-between;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/card/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,8BAA8B;EAChC;AACF","sourcesContent":[".flex {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n@media (max-width: 640px) {\n  .flex {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "styles_flex__p-Vyr"
};
export default ___CSS_LOADER_EXPORT___;
