import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { Container, Box } from "@chakra-ui/react";
import ProfileTab from "./components/profile/profile";
import OrdersTab from "./components/order/order";
import { useParams } from "react-router-dom";

const Profile = () => {
  const [active, setActive] = useState("profile");
  const navs = ["profile", "orders"];
  const params = useParams();

  useEffect(() => {
    if (params.id === "orders") {
      setActive("orders");
    }
  }, [params]);
  return (
    <Layout>
      <Container maxW="container.lg" pt="64px" pb="100px">
        <Box display="flex" gap="30px">
          {navs.map((data, idx) => (
            <Box
              key={data + idx}
              bg={active === data ? "#F2F4F7" : null}
              color={"#000"}
              fontWeight="500"
              p="10px"
              cursor="pointer"
              textTransform="capitalize"
              onClick={() => setActive(data)}
              borderRadius="15px"
            >
              {data}
            </Box>
          ))}
        </Box>

        <Box mt="24px">
          {active === "profile" ? <ProfileTab /> : <OrdersTab />}
        </Box>
      </Container>
    </Layout>
  );
};

export default Profile;
