import About from "../about";
import Cart from "../cart";
import Contact from "../contact";
import Faqs from "../faqs";
import Forgot from "../forgotPassword";
import Home from "../home";
import Login from "../login";
import Product from "../product";
import Profile from "../profile";
import ResetPassword from "../resetPassword";
import Shipping from "../shipping";
import Shop from "../shop";
import SignUp from "../signup";

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/shipping-and-return",
    element: <Shipping />,
  },
  {
    path: "/faqs",
    element: <Faqs />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/product/:id",
    element: <Product />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/forgot_password",
    element: <Forgot />,
  },
  {
    path: "/reset_password/:id",
    element: <ResetPassword />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/:id",
    element: <Profile />,
  },
];
