// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_large_screen__-AtJE {\n  display: block;\n}\n\n.styles_mobile__\\+eXrU {\n  display: none;\n}\n\n@media (max-width: 640px) {\n  .styles_large_screen__-AtJE {\n    display: none;\n  }\n\n  .styles_mobile__\\+eXrU {\n    display: block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/layout/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".large_screen {\n  display: block;\n}\n\n.mobile {\n  display: none;\n}\n\n@media (max-width: 640px) {\n  .large_screen {\n    display: none;\n  }\n\n  .mobile {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"large_screen": "styles_large_screen__-AtJE",
	"mobile": "styles_mobile__+eXrU"
};
export default ___CSS_LOADER_EXPORT___;
