import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_customer } from "../../../../redux/reducer";
import { getCustomer, getLoading } from "../../../../redux/selectors";
import { Box, Text, Avatar, Button, Spinner } from "@chakra-ui/react";
import styles from "../styles.module.css";
import { AiOutlineEdit } from "react-icons/ai";
import AddShippingLocation from "../../../cart/component/addShippingLocation";

const ProfileTab = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const loading = useSelector(getLoading);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(get_customer());
  }, [dispatch]);
  return (
    <>
      <AddShippingLocation
        open={show}
        close={() => setShow(false)}
        title={"Edit Address"}
      />
      {loading ? (
        <Box w="100%" h="100vh" display="grid" placeItems="center">
          <Spinner size="xl" color="#000" />
        </Box>
      ) : (
        <Box display="flex" gap="24px" flexDir="column">
          <Box border="1px solid #D0D5DD" borderRadius={"10px"} p="20px">
            <Box className={styles.personal}>
              <Avatar
                src={`https://robohash.org/${customer?.first_name}`}
                bg="#EAECF0"
                size="xl"
              />
              <Box>
                <Text
                  className={styles.name}
                >{`${customer?.first_name} ${customer?.last_name}`}</Text>
                <Text className={styles.sub}>{customer?.email_address}</Text>
                <Text className={styles.sub}>{customer?.phone_number}</Text>
              </Box>
            </Box>
          </Box>

          <Box border="1px solid #D0D5DD" borderRadius={"10px"} p="20px">
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Text fontSize="18px" fontWeight="600">
                Address
              </Text>

              <Button
                variant="outline"
                border="1px solid #D0D5DD"
                borderRadius={"20px"}
                color="#98A2B3"
                onClick={() => setShow(true)}
                size="sm"
                rightIcon={<AiOutlineEdit color="#98A2B3" />}
              >
                Edit
              </Button>
            </Box>

            <Box display="flex" gap="100px" alignItems="flex-start" mt="10px">
              <Box>
                <Text color="#98a2b3" fontSize="14px">
                  Country
                </Text>
                <Text fontWeight="600" color="#000" textTransform="capitalize">
                  {customer?.country || "Nigeria"}
                </Text>
              </Box>
              <Box>
                <Text color="#98a2b3" fontSize="14px">
                  City/State
                </Text>
                <Text
                  fontWeight="600"
                  color="#000"
                  textTransform={"capitalize"}
                >
                  {`${customer?.city || null}, ${customer?.state}`}
                </Text>
              </Box>
            </Box>

            <Box mt="24px">
              <Text color="#98a2b3" fontSize="14px">
                Street Address
              </Text>
              <Text fontWeight="600" color="#000">
                {customer?.address}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfileTab;
