import React, { useEffect, useState } from "react";
import { Box, Text, Image, Center } from "@chakra-ui/react";
import styles from "../styles.module.css";
import commaNumber from "comma-number";
import { update_quantity } from "../../../redux/reducer";
import { useDispatch } from "react-redux";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from "react-icons/ai";

const CartCard = ({ cart, remove }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (cart) {
      setQuantity(cart?.quantity);
    }
  }, [cart]);

  const handleUpdate = (type) => {
    let newQuantity = quantity;
    if (type === "Increment") {
      newQuantity += 1;
    } else {
      if (quantity === 1) return;
      newQuantity -= 1;
    }
    setQuantity(newQuantity);
    dispatch(update_quantity({ newQuantity, idx: cart.id }));
  };
  return (
    <Box className={styles.cart_card}>
      <Box display="flex" gap="16px" w="auto">
        <Image
          w="104px"
          h="104px"
          borderRadius={"2px"}
          border="1px solid #C9C9C9"
          src={cart?.images[0] ? cart?.images[0] : cart.images}
          objectFit="cover"
        />

        <Box>
          <Text
            fontSize="16px"
            fontWeight="600"
            mb="4px"
            color="#000"
            textTransform="capitalize"
          >
            {cart.name}
          </Text>

          <Box display="flex" flexDirection={"column"} gap="2px">
            {cart.size && (
              <Text color="#4F4F4F" fontSize={"14px"} fontWeight="400">
                Size:{" "}
                <span style={{ textTransform: "uppercase" }}>{cart.size}</span>
              </Text>
            )}

            {cart.color && (
              <Text color="#4F4F4F" fontSize={"14px"} fontWeight="400">
                Color:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {cart.color}
                </span>
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <Text fontSize="12px" fontWeight="500" mb="8px" textAlign="center">
          Quantity
        </Text>
        <Box display="inline-block">
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
            border="1px solid #E0E0E0"
            borderRadius="4px"
            gap="20px"
            padding="5px"
          >
            <Box
              w="21px"
              h="21px"
              display="grid"
              placeItems="center"
              cursor="pointer"
              onClick={() => handleUpdate("Decrement")}
            >
              <AiOutlineMinus />
            </Box>
            <Text>{quantity}</Text>

            <Box
              w="21px"
              h="21px"
              display="grid"
              placeItems="center"
              cursor="pointer"
              onClick={
                cart?.base_quantity === quantity
                  ? null
                  : () => handleUpdate("Increment")
              }
            >
              <AiOutlinePlus />
            </Box>
          </Box>
        </Box>

        <Center>
          <Box
            mt="10px"
            display="flex"
            gap="5px"
            alignItems="center"
            cursor="pointer"
            onClick={remove}
          >
            <AiOutlineDelete color="#D92D20" />
            <Text fontSize="12px" color="#D92D20">
              Remove
            </Text>
          </Box>
        </Center>
      </Box>

      <Box className={styles.price}>
        <Text color="#000" fontSize="14px" fontWeight="400" whiteSpace="nowrap">
          {cart.currency} {commaNumber(cart.price * cart.quantity)}
        </Text>
      </Box>
    </Box>
  );
};

export default CartCard;
