import React from "react";
import { Input } from "@chakra-ui/react";

const InputText = ({ type, placeholder, name, handleChange, value }) => {
  return (
    <Input
      type={type}
      name={name}
      placeholder={placeholder}
      p="9px"
      onChange={handleChange}
      value={value}
      border="1px solid #C9C9C9"
      borderRadius={"5px"}
      mt="8px"
      mb="16px"
      _placeholder={{
        color: "#C9C9C9",
        textTransform: "capitalize",
      }}
    />
  );
};

export default InputText;
