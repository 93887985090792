import React from "react";
import { Box } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const AccordionInfo = ({ merchant_shipping }) => {
  return (
    <Box>
      {" "}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize="16px"
                fontWeight="600"
              >
                States we deliver to
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel
            pb={4}
            color="#707070"
            fontSize="14px"
            fontWeight="400"
          >
            {merchant_shipping.map((data) => (
              <Box display={"flex"} gap="2px">
                {data.states.map((state) => (
                  <Box>{state},</Box>
                ))}
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default AccordionInfo;
