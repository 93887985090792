import React from "react";
import { Box } from "@chakra-ui/react";

const Badge = ({ status }) => {
  return (
    <Box display="inline-block">
      {status === "pending" ? (
        <Box
          bg="#FEF4D1"
          borderRadius="16px"
          p="4px"
          fontSize="12px"
          fontWeight="500"
          color="#D1A713"
          textAlign={"center"}
          textTransform="capitalize"
        >
          {" "}
          {status}
        </Box>
      ) : status === "success" ? (
        <Box
          bg="#CFEEE1"
          borderRadius="16px"
          p="4px"
          fontSize="12px"
          fontWeight="500"
          color="#10AA69"
          textAlign={"center"}
          textTransform="capitalize"
        >
          {status}
        </Box>
      ) : (
        <Box
          bg="#FCD1D1"
          borderRadius="16px"
          p="4px"
          fontSize="12px"
          fontWeight="500"
          color="#EE1717"
          textAlign={"center"}
          textTransform="capitalize"
        >
          {status}
        </Box>
      )}
    </Box>
  );
};

export default Badge;
