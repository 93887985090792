import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home";
import { routes } from "./routes/routes";

export const Template1 = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, idx) => (
          <Route exact path={route.path} key={idx} element={route.element} />
        ))}

        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};
