// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://assets.soracom.io/fonts/aeonik/Aeonik.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/satoshi);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n  font-family: \"Satoshi\", Aeonik, \"Raleway\", sans-serif;\n}\n\nbody {\n  margin: 0;\n  font-family: \"Satoshi\", Aeonik, \"Raleway\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\ntd th {\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,qDAAqD;AACvD;;AAEA;EACE,SAAS;EACT,qDAAqD;EACrD,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://assets.soracom.io/fonts/aeonik/Aeonik.css\");\n@import url(\"https://fonts.cdnfonts.com/css/satoshi\");\n\nhtml {\n  font-family: \"Satoshi\", Aeonik, \"Raleway\", sans-serif;\n}\n\nbody {\n  margin: 0;\n  font-family: \"Satoshi\", Aeonik, \"Raleway\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\ntd th {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
