import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Container, Grid, GridItem, Skeleton } from "@chakra-ui/react";
import Card from "../../../components/card";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getStore } from "../../redux/selectors";
import { get_store } from "../../redux/reducer";
import EmptyState from "../../../components/empty";
import CartIcon from "../../../components/cartIcon";
import Pagination from "../../../components/pagination";

const Shop = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const products = useSelector(getStore);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];
  const limit = 12;
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    const endOffset = itemOffset + limit;
    const currentItems = products.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(products.length / limit);
    setItems(currentItems);
    setPageCount(pageCount);
  }, [products, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % products.length;
    setItemOffset(newOffset);
  };

  return (
    <Layout>
      <Container maxW="container.xl" pb="100px">
        <CartIcon />

        {items.length >= 1 ? (
          <Grid
            templateColumns={{ base: "auto auto", md: "repeat(4,1fr)" }}
            gap="30px"
          >
            {items?.map((data) => (
              <GridItem>
                <Skeleton isLoaded={!loading}>
                  <Card key={data.id} content={data} />
                </Skeleton>
              </GridItem>
            ))}
          </Grid>
        ) : (
          <EmptyState text={"No Product Available"} />
        )}

        <Pagination pageCount={pageCount} changePage={handlePageClick} />
      </Container>
    </Layout>
  );
};

export default Shop;
