import React from "react";
import { Box, Text, Container, Avatar, Input } from "@chakra-ui/react";
import { HiMenuAlt2, HiOutlineUser } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
  getCustomer,
  getUser,
  getStore,
} from "../../pages/redux/selectors";
import { useEffect, useState } from "react";
import { get_cart, get_customer, get_store } from "../../pages/redux/reducer";
import { Link } from "react-router-dom";
import Mobile from "./mobile";
import { BsSearch } from "react-icons/bs";
import useDebounce from "../../hooks/useDebounce";
import styles from "./styles.module.css";

const MobileNavbar = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const token = localStorage.getItem("x-store-token");
  const customer = useSelector(getCustomer);
  const cart = useSelector(getCart);
  const [show, setShow] = useState(false);
  const products = useSelector(getStore);
  const [product, setProduct] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce(searchTerm, 300);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  const path = window.location.pathname;

  const routes = [
    {
      name: "shop",
      route: "/shop",
    },
  ];

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_customer());
    dispatch(get_cart());
  }, [dispatch]);

  useEffect(() => {
    if (debouncedSearch !== "") {
      const handleSearch = () => {
        const search = debouncedSearch.toLowerCase();
        const result = products.filter((data) => {
          return data?.name?.toLowerCase().includes(search);
        });
        setProduct(result);
      };

      handleSearch();
    } else {
      setProduct([]);
    }
  }, [debouncedSearch, products]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box pb="30px" pt="30px">
      <Mobile
        show={show}
        onClose={() => setShow(false)}
        data={routes}
        cart={cart}
      />
      <Container
        maxW="container.xl"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="grid"
          placeItems="center"
          boxShadow="0px 5px 7px 0px rgba(0, 0, 0, 0.10)"
          w="50px"
          h="50px"
          borderRadius="full"
          onClick={() => setShow(true)}
          cursor="pointer"
        >
          <HiMenuAlt2 color="#171717" size={30} />
        </Box>

        <Link to="/">
          <Text
            fontSize="32px"
            fontWeight={"700"}
            color="#171717"
            textTransform="capitalize"
          >
            {user.business_name}
          </Text>
        </Link>

        {token && Object.keys(customer)?.length >= 1 ? (
          <Link to="/profile">
            <Avatar
              src={`https://robohash.org/${customer?.first_name}`}
              boxShadow="0px 5px 7px 0px rgba(0, 0, 0, 0.10)"
              w="50px"
              h="50px"
            />
          </Link>
        ) : (
          <Link to="login">
            <Box
              display="grid"
              placeItems="center"
              boxShadow="0px 5px 7px 0px rgba(0, 0, 0, 0.10)"
              w="50px"
              h="50px"
              borderRadius="full"
            >
              <HiOutlineUser color="#171717" size={30} />
            </Box>
          </Link>
        )}
      </Container>

      {/* search box */}
      {path === "/cart" ||
        path === "/profile" ||
        (path === "/profile/orders" ? null : (
          <Container maxW="container.xl" mt="30px">
            <Box className={styles.dropdown_men}>
              <Box className={styles.dropdown_toggle}>
                {/* {search} */}
                <Box
                  display="flex"
                  borderRadius="10px"
                  boxShadow="0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
                  py="8px"
                  px="8px"
                  w="100%"
                  alignItems="stretch"
                >
                  <Input
                    type="text"
                    px="10px"
                    onChange={handleSearch}
                    border="none"
                    outline="none"
                    focusBorderColor="transparent"
                    placeholder="What are you looking for?"
                    _placeholder={{
                      color: "#C0C0C0",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  />
                  <Box
                    w="48px"
                    borderRadius="12px"
                    display="grid"
                    placeItems="center"
                    bg="#171717"
                  >
                    <BsSearch color="#fff" />
                  </Box>
                </Box>
                {/* search end */}
                {product?.length >= 1 && (
                  <Box className={styles.dropdown_content}>
                    <Box
                      className={styles.content}
                      display="flex"
                      flexDirection={"column"}
                      gap="5px"
                    >
                      {product.map((data, idx) => (
                        <Link key={data.id + idx} to={`/product/${data.id}`}>
                          <Box
                            p="5px"
                            textTransform={"capitalize"}
                            cursor="pointer"
                            fontSize="14px"
                            fontWeight="500"
                            _hover={{ background: "#C9C9C9" }}
                          >
                            {data.name}
                          </Box>
                        </Link>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Container>
        ))}

      {/* end of search box */}
    </Box>
  );
};

export default MobileNavbar;
