import React, { useEffect, useState } from "react";
import { Box, Button, Container, Text, Image } from "@chakra-ui/react";
import styles from "./styles.module.css";
import Layout from "../../../layout";
import Card from "../../../components/card";
import CardLoader from "../../../components/card/loader";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getLoading, getStore } from "../../redux/selectors";
import { get_customer, get_store } from "../../redux/reducer";
import { Link } from "react-router-dom";
import bg from "../../../assets/bg.jpg";
import EmptyState from "../../../components/empty";
import CartIcon from "../../../components/cartIcon";
import ToastMessage from "../../../components/toast";

const Home = () => {
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const products = useSelector(getStore);
  const customer = useSelector(getCustomer);
  const [hasData, setHasData] = useState(false);
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  useEffect(() => {
    dispatch(get_store(name));
  }, [dispatch, name]);

  useEffect(() => {
    dispatch(get_customer());
  }, [dispatch]);

  useEffect(() => {
    const result = {
      address: customer.address,
      state: customer.state,
      city: customer.city,
      country: customer.country,
    };

    const allValuesEmpty = Object?.values(result)?.every((value) => !value);
    setHasData(allValuesEmpty);
  }, [customer]);

  return (
    <Layout>
      <ToastMessage
        show={hasData && Object.keys(customer).length >= 1}
        onClose={() => setHasData(false)}
      >
        <Text>
          Please add your address{" "}
          <Link to="/profile" style={{ textDecoration: "underline" }}>
            Go to Profile
          </Link>
        </Text>
      </ToastMessage>
      <Box h="80vh" position={"relative"} w="100%">
        <Image src={bg} alt="bg" w="100%" h="80vh" objectFit="cover" />
        <Box
          zIndex="4"
          h="80vh"
          position={"absolute"}
          top="0"
          left="0"
          w="100%"
          bg="#00000080"
          display="grid"
          placeItems="center"
        >
          <Box>
            <Text className={styles.text}>Start Your Adventure</Text>
          </Box>
        </Box>
      </Box>
      <CartIcon />

      <Container maxW="container.xl" pb="100px">
        {products?.length >= 1 ? (
          <>
            <Text
              textAlign="left"
              textTransform="capitalize"
              fontWeight="700"
              fontSize="20px"
              pt="100px"
            >
              Featured
            </Text>
            <Box className={styles.grid}>
              {loading && (
                <>
                  {[...new Array(8)].map((_, idx) => (
                    <CardLoader key={idx} />
                  ))}
                </>
              )}
            </Box>
            <Box className={styles.grid}>
              {!loading && (
                <>
                  {products?.slice(0, 8)?.map((data) => (
                    <Card key={data.id} content={data} />
                  ))}
                </>
              )}
            </Box>
            <Box mt="77px" display="flex" justifyContent={"center"}>
              <Link to="/shop">
                <Button
                  bg="#171717"
                  color="#fff"
                  w="176px"
                  fontWeight="400"
                  fontSize="14px"
                  _hover={{ background: "#000" }}
                >
                  Shop All
                </Button>
              </Link>
            </Box>
          </>
        ) : (
          <EmptyState text="No Product Available" />
        )}
      </Container>
    </Layout>
  );
};

export default Home;
