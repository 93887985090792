import React from "react";
import Layout from "../../../layout";
import { Box, Container, Text } from "@chakra-ui/react";

const About = () => {
  return (
    <Layout>
      <Text
        textAlign="center"
        textTransform="capitalize"
        fontWeight="700"
        fontSize="20px"
        pt="48px"
        pb="48px"
      >
        About
      </Text>
      <Box h="100vh" bg="#F3F3F3" w="100%"></Box>
      <Container maxW="container.xl" pb="100px">
        <Text
          textAlign="center"
          color="#000"
          fontWeight="600"
          fontSize="24px"
          mb="24px"
          mt="83px"
        >
          We take care of the small details and believe that each daily action
          can make an impact.
        </Text>
        <Text
          textAlign="center"
          color="#707070"
          fontWeight="400"
          fontSize="24px"
        >
          I'm a paragraph. Click here to add your own text and edit me. It’s
          easy. Just click “Edit Text” or double click me to add your own
          content and make changes to the font. Feel free to drag and drop me
          anywhere you like on your page. I’m a great place for you to tell a
          story and let your users know a little more about you.​This is a great
          space to write a long text about your company and your services. You
          can use this space to go into a little more detail about your company.
          Talk about your team and what services you provide. Tell your visitors
          the story of how you came up with the idea for your business and what
          makes you different from your competitors. Make your company stand out
          and show your visitors who you are.
        </Text>
      </Container>
    </Layout>
  );
};

export default About;
